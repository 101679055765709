import { useRoutes } from 'react-router-dom';

// routes
// import MainRoutes from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
import FrontendRoutes from './FrontendRoutes';
// import EditingRoutes from './EditingRoutes';
// import NotFoundRoutes from './NotFoundRoutes';
import config from 'config';

// ==============================|| ROUTING RENDER ||============================== //
export function PublicRoutes() {
    return useRoutes([FrontendRoutes], config.basenamw);
}
