import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import MenuShader from 'ui-component/MenuShader';

// material-ui
import { Box, Button, Drawer, Input, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
// third-party
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { gql, useQuery } from '@apollo/client';
// project imports

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SET_ARCHIVE } from 'store/actions';
import ChevronIcon from 'ui-component/icons/ChevronIcon';
import FilterIcon from 'ui-component/icons/FilterIcon';

const MenuCard = styled('div')(({ theme }) => ({
    zIndex: 0,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',

    maskImage: 'linear-gradient(0deg, rgba(0, 0, 0, .25) 0%, rgba(0, 0, 0, .25) 30%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 1) 100%)'
}));

const GET_SEARCHABLE = gql`
    query searchable($lastName: String, $firstName: String) {
        searchableAuthors(lastName: $lastName, firstName: $firstName) {
            _id
            firstName
            lastName
            description
            slug
            igLink
            fbLink
            twLink
            inLink
            articles {
                _id
                title
                # author {
                #     _id
                #     firstName
                #     lastName
                # }
                # subtitle
                isPrivate
                # updatedAt
                # createdAt
                publishedAt
                slug
                category {
                    _id
                    name
                    slug
                }
            }
        }
    }
`;

const Archive = ({ archiveOpen, archiveToggle, window }) => {
    const theme = useTheme();
    const [foundArticles, setFoundArticles] = useState(null);
    const [gotData, setGotData] = useState(null);
    const [searchParam, setSearchParam] = useState('');
    const dispatch = useDispatch();

    const { data, error, log } = useQuery(GET_SEARCHABLE, { variables: { firstName: searchParam, lastName: searchParam } });
    // const searchable = useQuery(GET_PAGED_AUTHORS, { variables: { limit: authorsEnd, skip: authorsStart } });
    useEffect(() => {
        // document.title = `Cieloterra Design — Redazione`;
        // console.log(foundArticles);
        try {
            // console.log('paged', paged?.data?.pagedAuthors);
            // console.log('all', allAuthors?.data?.authors);
            // console.log('route', matchingRoute);
            // console.log(location);
            if (data && data !== undefined && data !== null) {
                setGotData(data?.searchableAuthors);
                setFoundArticles(data?.searchableAuthors);
            }
            // console.log('FOUND', foundArticles);
            // setTotalAuthors(allAuthors?.data?.authors);
            // setPage(1);
            // console.log('PAGE', page);
        } catch (err) {
            // console.log(err);
        }
    }, [data]);
    const itemHandler = (id) => {
        // dispatch({ type: MENU_OPEN, id });
        dispatch({ type: SET_ARCHIVE, id, archiveOpened: false });
    };
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    const [isFocused, setIsFocused] = useState(false);
    const BlurOverlay = styled('div')(({ theme }) => ({
        zIndex: 1,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        overflow: 'hidden',

        // transition: 'all .2s ease-in-out',
        backdropFilter: searchParam?.length > 0 && foundArticles.length > 0 ? 'blur(1rem) brightness(60%)' : 'none'
        // backdropFilter: 'blur(1rem) brightness(60%)'
        //! ADD TRANSITION
    }));

    const CustomInput = styled(Input)(({ theme }) => ({
        zIndex: `100 !important`,
        width: '90vw !important',
        maxWidth: '1280px !important',
        // display: 'flex',
        // flexDirection: 'row',
        padding: '0 1rem !important',
        borderBottom: matchUpMd ? '.5rem solid rgba(255,255,255,.8) !important' : '.2rem solid rgba(255,255,255,.8) !important',
        height: 'auto !important',
        fontSize: matchUpMd ? '4.5rem !important' : '2rem !important',
        // justifyContent: 'flex-end',
        '&>::placeholder': {
            color: `${theme.palette.background.paper} !important`,
            // fontSize: matchUpMd ? '4.5rem !important' : '2rem !important',
            fontSize: matchUpMd ? '3rem !important' : '2rem !important',
            opacity: '1 !important',
            textAlign: 'left !important'
        },
        '&>input': { color: `${theme.palette.background.paper} !important` }
    }));
    const drawer = (
        <Box sx={{ overflow: 'hidden' }}>
            <BrowserView>
                <Box
                    sx={{
                        zIndex: 100,
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ marginTop: '40vh' }}>
                        <CustomInput
                            inputComponent="input"
                            // onFocus={() => }
                            onBlur={() => setIsFocused(false)}
                            autoComplete="off"
                            autoFocus
                            onChange={(event) => {
                                setSearchParam(event.target.value);
                            }}
                            onKeyUp={(event) => setIsFocused(true)}
                            sx={{ width: '100%' }}
                            // inputComponent={TextField}
                            value={searchParam}
                            // value={searchParam}
                            // onKeyUp={(e) => {
                            //     e.target.focus();

                            //     // console.log(e.target.value);
                            //     setSearchParam(e.target.value);
                            //     e.preventDefault();
                            // }}
                            // onFocusCapture={(e) => {
                            //     setSearchParam(e.target.value);
                            //     // setSearchParam(e.target.value);
                            // }}
                            // value={searchParam}
                            name="search"
                            // value="cra"
                            className="archive-search-input"
                            variant="primary"
                            endAdornment={
                                <InputAdornment position="end">
                                    {/* <Button
                                        onClick={() => {
                                            setFoundArticles(data?.searchableAuthors);
                                        }}
                                        sx={{ height: 'auto', py: '0', mt: '1rem' }}
                                    >
                                        <SearchIcon size="1" iconFill={theme.palette.background.paper} />
                                    </Button> */}
                                    <Button
                                        disabled
                                        // onClick={() => {
                                        //     // console.log(data?.searchableAuthors);
                                        //     setFoundArticles(data?.searchableAuthors);
                                        // }}
                                        sx={{ height: 'auto', py: '0', mt: '1rem', opacity: '0.65' }}
                                    >
                                        <FilterIcon size="1" iconFill={theme.palette.background.paper} />
                                    </Button>
                                </InputAdornment>
                            }
                            disableUnderline
                            color="primary"
                            type="text"
                            placeholder="Cerca per parole chiave..."
                        />
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            zIndex: 99999999999999,
                            bottom: '0',
                            // height: '50vh',

                            width: '100%',
                            overflowY: 'scroll'
                        }}
                    >
                        {searchParam &&
                            foundArticles &&
                            // foundArticles.length > 0 &&
                            foundArticles.map((author, i) => (
                                <Box
                                    key={i}
                                    sx={{
                                        opacity: isFocused ? '1' : '0',
                                        maxWidth: '1280px',
                                        margin: '0 auto',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    {author?.articles?.map((article, j) => (
                                        <Box
                                            key={j}
                                            sx={{
                                                width: '100%',
                                                padding: '.5rem 2rem',
                                                margin: '0 auto -1px auto',
                                                display: 'flex',
                                                fontSize: '1rem',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                borderTop: '1px solid rgba(255,255,255,.8)',
                                                borderBottom: '1px solid rgba(255,255,255,.8)'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    width: '10vw',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                {format(new Date(article?.publishedAt), 'dd/MM/yyyy', { locale: it })}
                                            </Box>
                                            <Box
                                                sx={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    width: '10vw',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                {author?.firstName}&nbsp;{author?.lastName}
                                            </Box>
                                            <Box
                                                sx={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    width: '10vw',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                {ReactHtmlParser(article?.title)}
                                            </Box>

                                            <Box
                                                sx={{
                                                    // overflow: 'hidden',
                                                    // whiteSpace: 'nowrap',
                                                    // textOverflow: 'ellipsis',
                                                    width: '10vw',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                {article?.category?.name}
                                            </Box>

                                            <Button
                                                onClick={(item) => {
                                                    itemHandler(item.id);
                                                }}
                                                component={Link}
                                                to={
                                                    article?.category?.slug === 'milan-design-week'
                                                        ? `/editorial/${article?.category?.slug}/${new Date(
                                                              article?.publishedAt
                                                          ).getFullYear()}/${article.slug}`
                                                        : `/editorial/${article?.category?.slug}/${article.slug}`
                                                }
                                                disableElevation
                                                disableFocusRipple
                                                disableRipple
                                                disableTouchRipple
                                                variant="text"
                                                color={article.isPrivate ? 'secondary' : 'background'}
                                                size="medium"
                                                sx={{ fontWeight: 600, letterSpacing: '.1rem' }}
                                            >
                                                Vai all&apos;articolo&nbsp;&nbsp;
                                                <ChevronIcon size=".7" iconFill={theme.palette.primary.main} />
                                            </Button>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                    </Box>
                </Box>
            </BrowserView>
            <MobileView>
                <Box
                    sx={{
                        zIndex: 100,
                        height: '100vh',
                        overflow: 'hidden',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ postion: 'relative' }}>
                        <CustomInput
                            inputComponent="input"
                            autoComplete="off"
                            autoFocus
                            onChange={(event) => {
                                event.preventDefault();
                                setSearchParam(event.target.value);
                            }}
                            value={searchParam}
                            onKeyUp={(event) => setIsFocused(true)}
                            // onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            className="archive-search-input"
                            variant="primary"
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button sx={{ height: 'auto', py: '0' }}>
                                        <FilterIcon size="1" iconFill={theme.palette.background.paper} />
                                    </Button>
                                </InputAdornment>
                            }
                            // value={searchParam}
                            // onKeyUp={(e) => {
                            //     e.target.focus();

                            //     // console.log(e.target.value);
                            //     setSearchParam(e.target.value);
                            //     e.preventDefault();
                            // }}
                            // onFocusCapture={(e) => {
                            //     setSearchParam(e.target.value);
                            //     // setSearchParam(e.target.value);
                            // }}
                            // value={searchParam}
                            name="search"
                            // value="cra"

                            disableUnderline
                            color="primary"
                            type="text"
                            placeholder="Cerca per parole chiave..."
                            // placeholder="Cerca per parole chiave..."
                        />
                    </Box>
                    {searchParam && (
                        <Box
                            sx={{
                                margin: '1rem 0 0 0',
                                zIndex: 99999999999999,
                                bottom: '0 !important',
                                height: '50vh',
                                width: '100vw',
                                padding: '0 2rem',
                                // position: 'fixed',
                                left: '0',
                                overflowY: 'scroll',
                                overflowX: 'hidden'
                            }}
                        >
                            {foundArticles &&
                                // foundArticles.length > 0 &&
                                foundArticles.map((author, i) => (
                                    <Box key={i} sx={{ maxWidth: '1280px', margin: '0 auto', display: 'flex', flexDirection: 'column' }}>
                                        {author?.articles?.map((article, j) => (
                                            <Box
                                                key={j}
                                                sx={{
                                                    width: '100%',
                                                    padding: '1rem 1rem .5rem 1rem',
                                                    margin: '0 auto -1px auto',
                                                    display: 'flex',
                                                    fontSize: '1rem',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'space-between',
                                                    borderTop: '1px solid rgba(255,255,255,.8)',
                                                    borderBottom: '1px solid rgba(255,255,255,.8)'
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                    <Box
                                                        sx={{
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        {author?.firstName}&nbsp;{author?.lastName}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            textAlign: 'left',
                                                            fontWeight: '600'
                                                        }}
                                                    >
                                                        {format(new Date(article?.publishedAt), 'dd/MM/yyyy', { locale: it })}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        mt: '.5rem'
                                                    }}
                                                >
                                                    {ReactHtmlParser(article?.title)}
                                                </Box>
                                                {/* 
                                            <Button
                                                sx={{
                                                    textAlign: 'left',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    width: '10vw'
                                                }}
                                            > */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justiyfContent: 'space-between',
                                                        alignItems: 'center',
                                                        fontWeight: '600',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Box>{article?.category?.name}</Box>
                                                    {/* </Button> */}
                                                    {/* {article?.slug} */}
                                                    <Button
                                                        onClick={(item) => {
                                                            itemHandler(item.id);
                                                        }}
                                                        component={Link}
                                                        to={
                                                            article?.category?.slug === 'milan-design-week'
                                                                ? `/editorial/${article?.category?.slug}/${new Date(
                                                                      article?.publishedAt
                                                                  ).getFullYear()}/${article.slug}`
                                                                : `/editorial/${article?.category?.slug}/${article.slug}`
                                                        }
                                                        disableElevation
                                                        disableFocusRipple
                                                        disableRipple
                                                        disableTouchRipple
                                                        variant="text"
                                                        color={article.isPrivate ? 'secondary' : 'background'}
                                                        size="medium"
                                                        sx={{ fontWeight: 600, letterSpacing: '.1rem' }}
                                                    >
                                                        Leggi&nbsp;&nbsp;
                                                        <ChevronIcon size=".7" iconFill={theme.palette.primary.main} />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                ))}
                        </Box>
                    )}
                </Box>
            </MobileView>
        </Box>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
            {/* <Box sx={{ height: '100vh', overflowY: 'none' }}> */}
            {/* TODO: change component, maybe */}
            <Drawer
                container={container}
                variant="persistent"
                anchor="bottom"
                open={archiveOpen}
                onClose={archiveToggle}
                transitionDuration={0}
                sx={{
                    // zIndex: 9999999999999999,
                    ...(!archiveOpen && {
                        opacity: 0,
                        transition: theme.transitions.create('opacity', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: 200
                        })
                    }),
                    ...(archiveOpen && {
                        transition: theme.transitions.create('opacity', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: 300
                        }),
                        opacity: 1
                    }),
                    '& .MuiDrawer-paper': {
                        // zIndex: 0,
                        // transition: 'all .3s ease-in-out',
                        // width: frontendDrawerWidth,
                        // background: `linear-gradient(-5deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.bg} 100%)`,
                        color: theme.palette.background.paper,
                        // height: 'calc(100vh-72px)',
                        // display: 'flex',
                        // flexDirection: 'column',
                        // height: '100%',
                        // margin: '0 auto',
                        width: '100%'

                        // top: '72px'
                        // borderRight: 'none',
                        // [theme.breakpoints.up('md')]: {
                        //     // top: '66px'
                        // }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}

                <BlurOverlay />
                <MenuCard>
                    <Box
                        sx={{
                            boxShadow: '0px 20px 20px rgba(0, 0, 0, .1)',
                            opacity: 1,
                            background: theme.palette.background.paper,
                            width: matchUpMd ? '70vw' : '80vw',
                            maxWidth: '900px',
                            height: matchUpMd ? '70vh' : '50vh',
                            padding: '5rem 0'
                        }}
                    >
                        <Typography
                            sx={{ color: theme.palette.secondary.main, fontSize: matchUpMd ? '6rem' : '3rem' }}
                            variant="archiveExplore"
                        >
                            ESPLORA | cieloterradesign.com
                        </Typography>
                    </Box>
                </MenuCard>
                <MenuShader precision="lowp" pixelRatio={matchUpMd ? 0.1 : 0.2} />
            </Drawer>
            {/* </Box> */}
        </Box>
    );
};

Archive.propTypes = {
    archiveOpen: PropTypes.bool,
    archiveToggle: PropTypes.func,
    window: PropTypes.object
};

export default Archive;
