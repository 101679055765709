import { UserContext } from 'context/UserContext';
import { lazy, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
// material-ui
import { AppBar, Box, CssBaseline, LinearProgress, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// project imports
import { SET_MENU } from 'store/actions';
import { drawerWidth } from 'store/constant';
import QSERV_ENDPOINT from '../../constants';

const Header = Loadable(lazy(() => import('./Header')));
const Sidebar = Loadable(lazy(() => import('./Sidebar')));

// assets

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    margin: 0,
    marginTop: '88px',
    // minHeight: 'calc(100vh - 88px)',
    // background: theme.palette.grey[100],
    // width: `calc(100% - ${drawerWidth}px)`, // height: `calc(100% - 72px)`,
    padding: '0',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            marginRight: 0
            // width: `100%`
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: -drawerWidth,
            marginRight: 0
            // width: `100%`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: -drawerWidth,
            marginRight: 0,
            // width: `100%`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            // width: `100%`,
            // padding: '16px',
            marginRight: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            // width: `100%`,
            // padding: '16px',
            marginRight: 0
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: drawerWidth
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: drawerWidth
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [userContext, setUserContext] = useContext(UserContext);
    // const [me, setMe] = useState(null);
    const navigate = useNavigate();

    // const reverifyUser = (me) => {
    //     if (me) {
    //         setUserContext((oldVals) => ({ ...oldVals }));
    //     }
    // };
    // console.log(data);
    const verifyUser = useCallback(() => {
        fetch(`${QSERV_ENDPOINT}/users/refreshToken`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async (response, error) => {
                if (response && response.ok) {
                    const responseData = await response.json();
                    // console.log(responseData);
                    setUserContext((oldValues) => ({ ...oldValues, token: responseData.token }));
                    // if (data && data.token) {
                    if (!userContext.user) {
                        // wrap the above fetch in a try catch block
                        // if the fetch fails, navigate to login page
                        // if the fetch succeeds, set the user context

                        try {
                            fetch(`${QSERV_ENDPOINT}/users/me`, {
                                method: 'GET',
                                credentials: 'include',
                                // Pass authentication token as bearer token in header
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${responseData.token}`
                                }
                            }).then(async (response) => {
                                if (response.ok) {
                                    const meData = await response.json();
                                    // console.log('MEDATA', meData);

                                    setUserContext((oldVals) => ({ ...oldVals, user: meData }));
                                } else if (response && response.status === 500) {
                                    setUserContext((oldVals) => ({ ...oldVals, token: null, user: null }));

                                    navigate('/ctdadmin/login', { replace: true });
                                    // clearTimeout(verifyUser);
                                } else if (response && response.status === 401) {
                                    // console.log('401');
                                    if (userContext.token === null) {
                                        setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                                        navigate('/ctdadmin/login', { replace: true });
                                        // clearTimeout(verifyUser);
                                    }
                                } else {
                                    setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                                    navigate('/ctdadmin/login', { replace: true });
                                    // clearTimeout(verifyUser);
                                }
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                } else if (response && response.status === 500) {
                    navigate('/ctdadmin/login', { replace: true });
                    setUserContext((oldVals) => ({ ...oldVals, token: null, user: null }));
                    // clearTimeout(verifyUser);
                } else if (response.status === 401) {
                    // if (userContext.token === null) {
                    setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));
                    navigate('/ctdadmin/login', { replace: true });
                    // clearTimeout(verifyUser);
                    // }
                }

                setTimeout(verifyUser, 1 * 60 * 1000);
            })
            .catch((err) => {
                // siilently drop the error
                console.log();
                setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                navigate('/ctdadmin/login', { replace: true });
                // clearTimeout(verifyUser);
            });
    }, [setUserContext, navigate, userContext.user, userContext.token]);

    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        if (!userContext.user) {
            verifyUser();
        }

        dispatch({ type: SET_MENU, opened: !matchDownMd });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd, setUserContext, userContext.user]);
    // console.log('CONTEXT', userContext);
    // if (userContext && userContext.token) {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    zIndex: 1000,
                    width: '100vw',
                    top: 0,
                    left: 0,
                    // overflowX: 'hidden',
                    height: '88px',
                    bgcolor: theme.palette.grey[100],
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar
                    sx={{
                        bgcolor: theme.palette.grey[100],
                        height: '88px',
                        // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                        // color: theme.palette.primary.main,
                        // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                        transition: 'all .3s ease-in-out'
                    }}
                >
                    {userContext && userContext?.user && (
                        <Header context={userContext} setContext={setUserContext} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    )}
                </Toolbar>
            </AppBar>

            {/* drawer */}
            {userContext && userContext?.user && (
                <Sidebar visibilityHandler={userContext?.user?.role} drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            )}

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {userContext && userContext.user && <Outlet context={[userContext, setUserContext]} />}
                {!userContext ||
                    (!userContext.user && (
                        <Box
                            sx={{
                                // background: theme.palette.grey[100],
                                width: '100vw',
                                // marginTop: '88px',
                                height: 'calc(100vh - 88px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: 'auto', minWidth: 500, textAlign: 'left', height: 'auto' }}>
                                <LinearProgress
                                    variant="indeterminate"
                                    color="secondary"
                                    sx={{ borderRadius: '.25rem', width: '100%', mb: 4, height: '20px' }}
                                />
                                {/* <RefreshTwoTone sx={{ fontSize: '2rem', m: 0, p: 0, color: theme.palette.secondary.main }} /> */}
                                <Typography
                                    color={theme.palette.grey[400]}
                                    sx={{ fontWeight: 500, fontSize: '2rem' }}
                                    variant="articleBody"
                                >
                                    Carico la dashboard...
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                {/* breadcrumb */}
                {/* <Breadcrumbs separator={IconChevronRight} navigation={menuItems} icon title rightAlign /> */}
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};
export default MainLayout;
