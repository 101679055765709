import { lazy, useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { gql, useQuery } from '@apollo/client';
import {
    Alert,
    AppBar,
    Box,
    Button,
    Collapse,
    CssBaseline,
    IconButton,
    Modal,
    Snackbar,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import { SET_ARCHIVE, SET_MENU } from 'store/actions';
import Loadable from 'ui-component/Loadable';
import { Close } from '@mui/icons-material';
import PWASnackbar from 'ui-component/PWASnackbar';

const Archive = Loadable(lazy(() => import('./ArchiveMenu')));
const Footer = Loadable(lazy(() => import('./FrontendFooter')));
const Header = Loadable(lazy(() => import('./FrontendHeader')));
const Sidebar = Loadable(lazy(() => import('./FullMenu')));
const MdwHeader = Loadable(lazy(() => import('./MdwYearHeader')));

// import navigation from 'menu-items';
//                                 margin: '0 1em',
const GET_INTEGRATIONS = gql`
    query integrations {
        integrations {
            _id
            name
            type
            target
        }
    }
`;

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, route }) => ({
    ...theme.typography.mainContent,
    background:
        // route.pathname === '/'
        //     ? `linear-gradient(0deg, ${theme.palette.secondary.bg} 0%, ${theme.palette.primary.main} 100%)`
        //     : theme.palette.background.paper,
        route.pathname === '/' ? `none` : theme.palette.background.paper,
    ...(!open && {
        opacity: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // marginTop: '66px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen
        }),
        // transition: 'all .5s ease-in-out',

        [theme.breakpoints.up('md')]: {
            // marginLeft: -(frontendDrawerWidth - 20),
            // marginLeft: -frontendDrawerWidth
            // width: `calc(100% - ${frontendDrawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px',
            // width: `calc(100% - ${frontendDrawerWidth}px)`,
            // padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px',
            // width: `calc(100% - ${frontendDrawerWidth}px)`,
            // padding: '16px'
            // ,
            // marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        display: 'none',
        opacity: 0
        // overflowY: open ? 'hidden' : 'auto',
        // pointerEvents: 'none',

        // ,
        // marginLeft: 0,
        // borderBottomLeftRadius: 0,
        // borderBottomRightRadius: 0,
        // width: `calc(100% - ${frontendDrawerWidth}px)`
        // ,
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px'
        // }
    })
}));

const InstallPrompt = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '0',
        padding: '0',
        boxShadow: 'none',
        '& .MuiSnackbarContent-message': {
            padding: '0'
        }
    }
}));
// ==============================|| MAIN LAYOUT ||============================== //

const FrontendLayout = () => {
    const theme = useTheme();
    const route = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    // Handle compressed navbar
    const [isCompressed, setCompressed] = useState(false);

    const [socials, setSocials] = useState(null);

    //! SQNQ: SINE QUA NON QUERIES
    const { data, error, loading } = useQuery(GET_INTEGRATIONS);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const rightArchiveOpened = useSelector((state) => state.customization.archiveOpened);
    // const leftDrawerClosed = useSelector((state) => state.customization.closed);
    const navbarStyleHandler = () => {
        if (route.pathname === '/' && isCompressed) {
            return 'rgba(101, 175, 255,.92)';
        }
        if (route.pathname === '/' && !isCompressed) {
            return 'rgba(0,0,0,0)';
        }
        if (route.pathname !== '/' && isCompressed) {
            return 'rgba(101, 175, 255,.92)';
        }
        return 'rgba(255,255,255,.87)';
    };
    const navHeightHandler = () => {
        if (!matchUpMd && route.pathname.startsWith('/editorial/milan-design-week') && isCompressed) {
            return '120px';
        }
        if (matchUpMd && route.pathname.startsWith('/editorial/milan-design-week') && isCompressed) {
            return '102px';
        }
        if (route.pathname.startsWith('/editorial/milan-design-week') && !isCompressed) {
            return '16em';
        }
        if (!route.pathname === '/editorial/milan-design-week' || isCompressed) {
            return '72px';
        }
        if (!route.pathname === '/editorial/milan-design-week' || !isCompressed) {
            return '16em';
        }
        return '72px';
    };
    const sectionHandler = () => {
        if (route.pathname === '/') {
            return 'none';
        }
        if (!route.pathname === '/' || isCompressed) {
            return 'inline';
        }
        // if (!route.pathname === '/' && !isCompressed) {
        //     return 'none';
        // }
        return 'none';
    };
    const marginHandler = () => {
        if (matchUpMd && isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '102px';
        }
        if (!matchUpMd && isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '120px';
        }
        if (matchUpMd && !isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '16em';
        }
        if (!matchUpMd && !isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '120px';
        }
        if (!matchDownSm) {
            return '16em';
        }
        if (!isCompressed) {
            return '72px';
        }

        return '72px';
    };

    // if (!route.pathname === '/' && !isCompressed) {
    //     return 'none';
    // }
    const mobileNavbarHandler = () => {
        if (route.pathname === '/') {
            return 'rgba(101, 175, 255,.92)';
        }
        return 'rgba(255,255,255,.92)';
    };

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const handleArchiveToggle = () => {
        dispatch({ type: SET_ARCHIVE, archiveOpened: !rightArchiveOpened });
    };

    useEffect(() => {
        if (data) {
            setSocials(data?.integrations);
            dispatch({ type: SET_MENU, opened: false });
            dispatch({ type: SET_ARCHIVE, archiveOpened: false });
            // console.log(socials);
            // setFirstName(data?.authorBySlug.firstName);
            // setFirstName(data?.authorBySlug.firstName);
            // document.title = `Cieloterra Design — ${data?.authorBySlug.firstName} ${data?.authorBySlug.lastName}`;
        }
        const handler = () => {
            setCompressed((isCompressed) => {
                if (!isCompressed && (document.body.scrollTop > 66 || document.documentElement.scrollTop > 66)) {
                    return true;
                }

                if (isCompressed && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
                    return false;
                }

                return isCompressed;
            });
        };

        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
    }, [data, dispatch]);

    if (loading || !data || data === null || data === undefined) return <div> </div>;
    const archiveHandler = () => {
        const d = '';
        return <Archive archiveOpen={rightArchiveOpened} archiveToggle={handleArchiveToggle} />;
    };
    return (
        // TODO: watch width

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',

                border: 'none !important'
            }}
        >
            <CssBaseline />

            <MobileView>
                <PWASnackbar />

                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 1000000,
                        width: '100vw',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        height: '72px',
                        boxShadow: leftDrawerOpened || rightArchiveOpened || !isCompressed ? 'none' : '0px 5px 20px rgba(0, 0, 0, .1)',

                        transition: leftDrawerOpened || rightArchiveOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: '72px',
                            bgcolor: leftDrawerOpened || rightArchiveOpened ? 'rgba(0,0,0,0)' : mobileNavbarHandler,
                            // bgcolor: leftDrawerOpened || rightArchiveOpened ? 'rgba(0,0,0,0)' : 'rgba(101, 175, 255,.73)',
                            backdropFilter: 'blur(.74rem) ',
                            // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                            // color: theme.palette.primary.main,
                            // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            vizHandler={route.pathname === '/' || !isCompressed}
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={
                                route.pathname === '/' || leftDrawerOpened || rightArchiveOpened
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main
                            }
                            isCompressed
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                    <Toolbar
                        sx={{
                            p: 0,
                            minHeight: '3rem !important',
                            maxHeight: '3rem !important',
                            height: '3rem !important',
                            bgcolor: theme.palette.secondary.bg,
                            transition: 'all .3s ease-in-out',
                            display:
                                route.pathname.startsWith('/editorial/milan-design-week') && !leftDrawerOpened && !rightArchiveOpened
                                    ? 'block'
                                    : 'none'
                        }}
                    >
                        <MdwHeader
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={theme.palette.background.paper}
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </MobileView>
            <BrowserView>
                <PWASnackbar desktop />

                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 1000000,
                        height: navHeightHandler,
                        width: '100vw',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        transition: leftDrawerOpened || rightArchiveOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: isCompressed ? '0px 5px 20px rgba(0, 0, 0, .1)' : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: '72px',
                            bgcolor: leftDrawerOpened || rightArchiveOpened ? 'rgba(0,0,0,0)' : navbarStyleHandler,
                            backdropFilter: isCompressed ? 'blur(.74rem) ' : 'none',
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            vizHandler
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={
                                route.pathname === '/' || isCompressed || leftDrawerOpened || rightArchiveOpened
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main
                            }
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                    <Toolbar
                        sx={{
                            py: 0,
                            minHeight: matchUpMd ? '1.875rem !important' : '3rem !important',
                            maxHeight: matchUpMd ? '1.875rem !important' : '3rem !important',
                            height: matchUpMd ? '1.875rem !important' : '3rem !important',
                            bgcolor: theme.palette.secondary.bg,
                            transition: 'all .3s ease-in-out',
                            display:
                                route.pathname.startsWith('/editorial/milan-design-week') && !leftDrawerOpened && !rightArchiveOpened
                                    ? 'block'
                                    : 'none'
                        }}
                    >
                        <MdwHeader
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={theme.palette.background.paper}
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </BrowserView>
            {/* drawer */}
            {archiveHandler()}
            {socials && <Sidebar socialLinks={socials} drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />}
            {/* archive */}
            {/* main content */}
            <Main
                sx={{
                    // background: 'red',
                    marginTop: marginHandler,
                    ...(leftDrawerOpened &&
                        rightArchiveOpened && {
                            transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                            opacity: 0
                        }),
                    ...(!leftDrawerOpened &&
                        !rightArchiveOpened && {
                            transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                            opacity: 1
                        })
                }}
                theme={theme}
                open={leftDrawerOpened || rightArchiveOpened}
                route={route}
            >
                {/* breadcrumb */}
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}

                {/* <Suspense fallback={<div>Loading...</div>}> */}
                <Outlet />
                {/* </Suspense> */}
            </Main>
            {socials && <Footer socialLinks={socials} isOverlayOpened={leftDrawerOpened || rightArchiveOpened} />}
            {/* <Customization /> */}
        </Box>
    );
};

export default FrontendLayout;
