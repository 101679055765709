// material-ui
import { Typography, Box, Button, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

// project imports
import NavGroup from './NavGroup';
import { styled, useTheme } from '@mui/material/styles';

import { basePages, mobBasePages } from 'menu-items';
import { useEffect, useState } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const FooterMenu = ({ socialLinks }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));

    const navItems = basePages.items.map((item) => {
        switch (item.type) {
            case 'page':
                return <NavGroup socials={socialLinks} key={item.id} item={item} />;
            // case 'page':
            //     return <NavGroup key={item.id} item={item} />;
            // case 'social':
            //     return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const mobItems = mobBasePages.items.map((item) => {
        switch (item.type) {
            case 'page':
                return <NavGroup socials={socialLinks} key={item.id} item={item} />;
            // case 'page':
            //     return <NavGroup key={item.id} item={item} />;
            // case 'social':
            //     return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    if (socialLinks === null) return <div />;

    return matchUpMd ? (
        <Box
            sx={{
                width: '100vw',
                margin: '1rem auto',

                // margin: '0 2rem',

                // height: '100vh',
                // pl: '3em',

                zIndex: 10,
                position: 'fixed',
                bottom: 0,
                left: 0,
                // background: 'red',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch'
            }}
        >
            {navItems}
        </Box>
    ) : (
        <Box
            sx={{
                width: '100vw',
                margin: '0 auto 1rem 0',

                // margin: '0 2rem',

                // height: '100vh',
                // pl: '3em',

                zIndex: 10,
                position: 'fixed',
                bottom: 0,
                left: 0,
                // background: 'red',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch'
            }}
        >
            {mobItems}
        </Box>
    );
};

FooterMenu.propTypes = {
    socialLinks: PropTypes.array
};

export default FooterMenu;
