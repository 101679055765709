import { gql, useQuery } from '@apollo/client';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// routing
import { PublicRoutes } from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import { Box } from '@mui/system';
import NavigationScroll from 'layout/NavigationScroll';
import { Suspense, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

// ==============================|| APP ||============================== //

const GET_INTEGRATION = gql`
    query integrationByType {
        integrationByType(type: "ga") {
            name
            type
            target
        }
    }
`;

const App = () => {
    const customization = useSelector((state) => state.customization);
    const { data } = useQuery(GET_INTEGRATION);
    const [analyticsId, setAnalyticsId] = useState(null);
    const [cookies, setCookie] = useCookies();
    // get _iub variable from index.html
    useEffect(() => {
        if (data) {
            setAnalyticsId(data.integrationByType.target);
        }
    }, [data]);

    useEffect(() => {
        const iubendaCookie = cookies['_iub_cs-70416516'];

        if (iubendaCookie && analyticsId) {
            console.log('⚙️✅ Cookies e privacy policy accettati');
            // check if iubendaCookie.purposes object contains all true values
            const purposes = iubendaCookie.purposes;
            const allTrue = Object.values(purposes).every((purpose) => purpose === true);
            if (allTrue) {
                console.log('⚙️✅ Tutti i cookie sono stati accettati');
                // if (process.env.GA_TRACKING_ID) {
                ReactGA.initialize(analyticsId);
                console.log('⚙️🌎 Tag GA inizializzato. Tracking attivo');
            } else {
                console.log('⚙️🚫 Accettati soli cookie necessari');
            }
        }
    }, [cookies, analyticsId]);
    // listen for changes in the iubenda cookie, and reinitialize GA if the user accepts all cookies

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <PublicRoutes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
