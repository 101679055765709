// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const FacebookIcon = ({ iconFill, size }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg
            style={{ transform: `scale(${size})` }}
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.1217 10.0609C20.1217 4.50304 15.6187 0 10.0609 0C4.50304 0 0 4.50304 0 10.0609C0 15.0824 3.67911 19.2446 8.48884 20V12.9692H5.93306V10.0609H8.48884V7.84422C8.48884 5.32292 9.98986 3.93022 12.2888 3.93022C13.3899 3.93022 14.5412 4.12657 14.5412 4.12657V6.60122H13.2722C12.0227 6.60122 11.6329 7.37688 11.6329 8.17241V10.0609H14.4231L13.9769 12.9692H11.6329V20C16.4426 19.2446 20.1217 15.0824 20.1217 10.0609Z"
                fill={iconFill}
            />
        </svg>
    );
};
FacebookIcon.propTypes = {
    iconFill: PropTypes.string,
    size: PropTypes.string
};

export default FacebookIcon;
