// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const SearchIcon = ({ iconFill }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */ <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.6736 20.7531L18.9998 16.0793C18.7889 15.8683 18.5029 15.7512 18.2029 15.7512H17.4388C18.7326 14.0963 19.5014 12.0149 19.5014 9.75071C19.5014 4.36438 15.137 0 9.75071 0C4.36438 0 0 4.36438 0 9.75071C0 15.137 4.36438 19.5014 9.75071 19.5014C12.0149 19.5014 14.0963 18.7326 15.7512 17.4388V18.2029C15.7512 18.5029 15.8683 18.7889 16.0793 18.9998L20.7531 23.6736C21.1937 24.1143 21.9063 24.1143 22.3423 23.6736L23.6689 22.3469C24.1096 21.9063 24.1096 21.1937 23.6736 20.7531ZM9.75071 15.7512C6.43641 15.7512 3.75027 13.0697 3.75027 9.75071C3.75027 6.43641 6.43172 3.75027 9.75071 3.75027C13.065 3.75027 15.7512 6.43172 15.7512 9.75071C15.7512 13.065 13.0697 15.7512 9.75071 15.7512Z"
                fill={iconFill}
            />
        </svg>
    );
};
SearchIcon.propTypes = {
    iconFill: PropTypes.string
};

export default SearchIcon;
