import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// material-ui
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import featherIcon from 'assets/images/raster/feather-cross_ico.webp';
import { lazy, useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Iubenda from 'react-iubenda-policy';
import Loadable from 'ui-component/Loadable';

const FacebookIcon = Loadable(lazy(() => import('ui-component/icons/brands/FacebookIcon')));
const InstagramIcon = Loadable(lazy(() => import('ui-component/icons/brands/InstagramIcon')));
const NewsletterInput = Loadable(lazy(() => import('ui-component/NewsletterInput')));

// project imports

// ==============================|| MAIN FOOTER ||============================== //

const Footer = ({ isOverlayOpened, socialLinks }) => {
    const theme = useTheme();
    const route = useLocation();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    const [socials, setSocials] = useState(null);
    const [policyID, setPolicyID] = useState(null);
    const [email, setEmail] = useState(null);
    const [fb, setFb] = useState(null);
    const [ig, setIg] = useState(null);
    const [mc, setMc] = useState(null);

    const CrossingFeathers = styled('img')(({ theme }) => ({
        width: '15rem',
        height: '100%',
        objectFit: 'contain',
        margin: '-1.5rem 0 3rem 0'
    }));

    useEffect(() => {
        if (socialLinks) {
            // console.log(socialLinks);
            setSocials(socialLinks);

            // const emailTarget = socialLinks?.filter((social) => social?.type === 'email')[0];
            const emailTarget = socialLinks?.find((social) => social?.type === 'email');
            const fbTarget = socialLinks?.find((social) => social?.type === 'fb');
            const igTarget = socialLinks?.find((social) => social?.type === 'ig');
            const mcTarget = socialLinks?.find((social) => social?.type === 'mc');
            const iuTarget = socialLinks?.find((social) => social?.type === 'iu');
            // console.log(emailTarget);
            if (emailTarget) {
                setEmail(emailTarget);
            }
            if (fbTarget) {
                setFb(fbTarget);
            }
            if (igTarget) {
                setIg(igTarget);
            }
            if (mcTarget) {
                setMc(mcTarget);
            }
            if (iuTarget) {
                setPolicyID(iuTarget?.target);
            }
            // console.log(iuTarget);
            // console.log(policyID);
            // console.log(ig);
            // console.log(email);

            // }
        }
    }, [socialLinks, socials, policyID]);

    if (socialLinks === null) return <div />;

    return (
        <div
            style={{
                display: isOverlayOpened ? 'none' : 'block',
                opacity: isOverlayOpened ? '0' : '1'
            }}
        >
            <Box
                sx={{
                    width: matchUpMd ? '100%' : '85vw',
                    borderTop: route.pathname === '/' ? `1px solid ${theme.palette.background.paper}` : 'none',
                    margin: '-5rem auto 5rem auto'
                }}
            />
            <Box
                sx={{
                    bgcolor: route.pathname === '/' ? 'none' : theme.palette.background.paper
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: matchUpMd ? '100%' : 'auto',
                            margin: matchUpMd ? '0 auto' : '0 2rem',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {/* NEWSLETTER */}
                        <CrossingFeathers src={featherIcon} alt="globe" />
                        <Typography
                            color={route.pathname === '/' ? theme.palette.background.paper : theme.palette.text.body}
                            variant="subscribeHeader"
                            sx={{ mb: '2rem', textAlign: 'center' }}
                        >
                            Iscriviti alla nostra newsletter!
                        </Typography>
                        <NewsletterInput iuTarget={policyID} mcTarget={mc} />
                        <Box
                            sx={{
                                margin: matchUpMd ? '6rem 0 1rem 0' : '2rem 0 1rem 0',
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: route.pathname === '/' ? theme.palette.background.paper : theme.palette.primary.main,
                                    fontSize: matchUpMd ? '20.5vw' : '18vw'
                                }}
                                variant="footerClaim"
                            >
                                CIELOTERRA
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: matchUpMd ? '20.5vw' : '18vw',
                                    color: route.pathname === '/' ? theme.palette.primary.main : 'black'
                                }}
                                variant="footerClaim"
                            >
                                &nbsp;/&nbsp;
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: matchUpMd ? '20.5vw' : '18vw',
                                    color: route.pathname === '/' ? theme.palette.background.paper : theme.palette.primary.main
                                }}
                                variant="footerClaim"
                            >
                                Lo Spazio Del Design.
                            </Typography>
                        </Box>
                    </Box>

                    <BrowserView>
                        <Box
                            sx={{
                                background: 'black',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                padding: '0 1em'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    [theme.breakpoints.up('md')]: {
                                        maxWidth: '1280px'
                                    },
                                    padding: '1em 0',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography
                                    onClick={(e) => {
                                        window.location.href = `mailto:${email?.target}@cieloterradesign.com?subject=Contatto da cieloterradesign.com`;
                                        e.preventDefault();
                                    }}
                                    variant="footerRef"
                                    sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                >
                                    {email?.target}@cieloterradesign.com
                                </Typography>

                                <Typography variant="footerRef">
                                    CIELOTERRA WEBMAGAZINE | © {new Date().getFullYear()}. All rights reserved.
                                </Typography>

                                {/* <Iubenda id={policyID} type="privacy" styling="black"> */}
                                <Iubenda id={policyID} type="privacy" styling="nostyle">
                                    <Typography variant="footerRef">COOKIES &amp; PRIVACY POLICY</Typography>
                                </Iubenda>
                            </Box>
                        </Box>
                    </BrowserView>
                    <MobileView>
                        <Box
                            sx={{
                                background: 'black',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                mb: 0,
                                height: '2.75rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '0 1rem'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',

                                    // padding: '1em 0',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'calc(100vw/3)',

                                        // padding: '1em 0',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start'
                                    }}
                                >
                                    <Typography variant="footerRef"> CIELOTERRA | © {new Date().getFullYear()}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'calc(100vw/3)',

                                        // padding: '1em 0',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Button
                                        href={`https://fb.me/${email?.target}`}
                                        target="_blank"
                                        disableRipple
                                        variant="text"
                                        color="background"
                                        sx={{
                                            padding: 0,
                                            // top: '1rem',
                                            minWidth: 'auto',
                                            width: '1rem',
                                            height: '1rem',
                                            mx: '.5rem',
                                            borderRadius: '5rem'
                                            // aspectRatio: '1/1'
                                        }}
                                    >
                                        <FacebookIcon iconFill={theme.palette.background.paper} />
                                    </Button>
                                    <Button
                                        href={`https://instagram.com/${ig?.target}`}
                                        target="_blank"
                                        disableRipple
                                        variant="text"
                                        color="background"
                                        sx={{
                                            padding: 0,
                                            // top: '1rem',
                                            minWidth: 'auto',
                                            width: '1rem',
                                            height: '1rem',
                                            mx: '.5rem',
                                            borderRadius: '5rem'
                                            // aspectRatio: '1/1'
                                        }}
                                    >
                                        <InstagramIcon iconFill={theme.palette.background.paper} />
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'calc(100vw/3)',

                                        padding: '0',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Iubenda id={policyID} type="privacy" styling="nostyle">
                                        <Typography variant="footerRef" sx={{ textDecoration: 'none' }}>
                                            PRIVACY POLICY
                                        </Typography>
                                    </Iubenda>
                                </Box>
                            </Box>
                        </Box>
                    </MobileView>
                </Box>
            </Box>
        </div>
    );
};

Footer.propTypes = {
    isOverlayOpened: PropTypes.bool,
    socialLinks: PropTypes?.array
};

export default Footer;
