import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, Button, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
// assets
import FacebookIcon from 'ui-component/icons/brands/FacebookIcon';
import InstagramIcon from 'ui-component/icons/brands/InstagramIcon';

// project imports
import { MENU_OPEN, ARCHIVE_OPEN, SET_MENU, SET_ARCHIVE } from 'store/actions';
import config from 'config';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, fbTarget, igTarget }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));

    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon;
    const itemIcon = item?.icon && <Icon stroke={1.5} size="1.3rem" />;

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        // dispatch({ type: MENU_OPEN, id });
        dispatch({ type: SET_MENU, id, opened: false });
        dispatch({ type: SET_ARCHIVE, id, archiveOpened: false });
    };

    // console.warn('IGTARGET', igTarget);
    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
            dispatch({ type: ARCHIVE_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);
    if (fbTarget === null || igTarget === null) return <div />;

    if (item.external && matchUpMd) {
        return fbTarget?.type === item.icon ? (
            <Button
                {...listItemProps}
                disabled={item.disabled}
                target="_blank"
                disableRipple
                variant="outlined"
                color="background"
                href={`https://fb.me/${fbTarget?.target}`}
                sx={{
                    padding: 0,
                    top: '1rem',
                    minWidth: 'auto',
                    width: '2.5rem',
                    height: '2.5rem',
                    mx: '.5rem',
                    pl: '.1rem',
                    borderRadius: '5rem'
                    // aspectRatio: '1/1'
                }}
            >
                {fbTarget?.type === item.icon && <FacebookIcon iconFill={theme.palette.background.paper} />}
            </Button>
        ) : (
            igTarget?.type === item.icon && (
                <Button
                    {...listItemProps}
                    disabled={item.disabled}
                    target="_blank"
                    disableRipple
                    variant="outlined"
                    color="background"
                    href={`https://instagram.com/${igTarget?.target}`}
                    sx={{
                        padding: 0,
                        top: '1rem',
                        minWidth: 'auto',
                        width: '2.5rem',
                        height: '2.5rem',
                        mx: '.5rem',
                        pl: '.05rem',
                        borderRadius: '5rem'
                        // aspectRatio: '1/1'
                    }}
                >
                    {igTarget?.type === item.icon && <InstagramIcon iconFill={theme.palette.background.paper} />}
                </Button>
            )
        );
    }
    return (
        <Button
            {...listItemProps}
            disabled={item.disabled}
            disableRipple
            sx={{
                // borderRadius: `${customization.borderRadius}px`,
                borderRadius: 0,
                // mx: '.5em',
                // alignItems: 'flex',
                // backgroundColor: level > 1 ? 'transparent !important' : 'inherit'
                // py: level > 1 ? 1 : 1.25
                // pl: `${level * 24}px`
                minWidth: '5rem'
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            {/* <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon> */}
            <Typography
                sx={{ fontSize: '5rem' }}
                // variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'activeTopicItem' : 'topicItem'}
                variant="activeTopicItem"
                // color="inherit"
            >
                {item.title}
            </Typography>
            {/* {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )} */}
        </Button>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
    fbTarget: PropTypes.object,
    igTarget: PropTypes.object
};

export default NavItem;
