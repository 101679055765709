// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const ChevronIcon = ({ iconFill, left, size }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg
            style={{ transform: left ? `rotate(180deg) scale(${size})` : `scale(${size})` }}
            width="18"
            height="22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.008 0C7.84 0 8.608 0.288002 9.312 0.864002L16.896 8.448C17.536 9.152 17.856 9.952 17.856 10.848C17.856 11.68 17.536 12.416 16.896 13.056L9.312 20.64C8.544 21.28 7.776 21.6 7.008 21.6H0V15.168H5.664L10.08 10.752L5.664 6.432H0V0H7.008Z"
                fill={iconFill}
            />
        </svg>
    );
};
ChevronIcon.propTypes = {
    iconFill: PropTypes.string,
    size: PropTypes.string,
    left: PropTypes.bool
};

export default ChevronIcon;
