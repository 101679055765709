import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NavigationScroll from 'layout/NavigationScroll';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography, Button, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MenuIcon from 'ui-component/icons/MenuIcon';
import SearchIcon from 'ui-component/icons/SearchIcon';
import CloseIcon from 'ui-component/icons/CloseIcon';
// import SearchSection from './SearchSection';
// import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const MdwHeader = ({ handleLeftDrawerToggle, updateYear, isCompressed, styleHandler, routeHandler, drawerOpened, routeName }) => {
    const theme = useTheme();
    const route = useLocation();
    const navigate = useNavigate();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    const mdwYears = [
        { title: '2024', route: 'editorial/milan-design-week/2024', active: route.pathname === '/editorial/milan-design-week/2024' },
        { title: '2023', route: 'editorial/milan-design-week/2023', active: route.pathname === '/editorial/milan-design-week/2023' },
        { title: '2022', route: 'editorial/milan-design-week/2022', active: route.pathname === '/editorial/milan-design-week/2022' },
        { title: '2021', route: 'editorial/milan-design-week/2021', active: route.pathname === '/editorial/milan-design-week/2021' },
        { title: '2020', route: 'editorial/milan-design-week/2020', active: route.pathname === '/editorial/milan-design-week/2020' },
        { title: '2019', route: 'editorial/milan-design-week/2019', active: route.pathname === '/editorial/milan-design-week/2019' },
        { title: '2018', route: 'editorial/milan-design-week/2018', active: route.pathname === '/editorial/milan-design-week/2018' },
        { title: '2017', route: 'editorial/milan-design-week/2017', active: route.pathname === '/editorial/milan-design-week/2017' }
    ];
    return (
        <>
            {matchUpMd && (
                <Box
                    sx={{
                        // width: 228,
                        display: 'flex',
                        width: '100%',
                        margin: '0 auto',
                        // height: '1.875rem',

                        // alignItems: '',

                        justifyContent: 'space-between',
                        [theme.breakpoints.up('md')]: {
                            maxWidth: '1280px'
                        }
                    }}
                >
                    <Box
                        sx={{
                            // width: 228,
                            display: 'flex',
                            width: '48.75rem',
                            margin: '0 auto',
                            // height: '1.875rem',

                            // alignItems: '',

                            justifyContent: 'space-between',
                            [theme.breakpoints.up('md')]: {
                                maxWidth: '1280px'
                            }
                        }}
                    >
                        {mdwYears.map((item, i) => (
                            <Button
                                onClick={() => {
                                    updateYear(item.title);
                                    // routeHandler(item.route);
                                }}
                                sx={{ height: '1.875rem', color: theme.palette.background.paper, borderRadius: 0, fontWeight: 'bold' }}
                                key={i}
                                component={Link}
                                to={item.route}
                                variant={item.active ? 'contained' : 'text'}
                                color={item.active ? 'primary' : 'background'}
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                            >
                                {item.title}
                            </Button>
                        ))}
                    </Box>
                </Box>
            )}
            {!matchUpMd && (
                <Box
                    sx={{
                        maskImage: `linear-gradient(90deg, rgba(0,0,0,0)  0%, rgba(0, 0, 0, 1) 30%, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)`,
                        display: 'flex',
                        margin: '0',
                        padding: '0',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                        overflowX: 'scroll',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '::-webkit-scrollbar': {
                            display: 'none'
                        }

                        // alignItems: '',

                        // [theme.breakpoints.up('md')]: {
                        //     maxWidth: '1280px'
                        // }
                    }}
                >
                    <Box
                        sx={{
                            // width: 228,
                            display: 'flex',
                            width: '200vw',
                            padding: '0 33vw',
                            // margin: 0,
                            // height: '1.875rem',

                            // alignItems: '',

                            justifyContent: 'space-between'
                            //     [theme.breakpoints.up('md')]: {
                            //         // maxWidth: '1280px'
                            //     }
                        }}
                    >
                        {mdwYears.map((item, i) => (
                            <Button
                                onClick={() => updateYear()}
                                sx={{
                                    fontSize: '1rem',
                                    height: '3rem',
                                    color: theme.palette.background.paper,
                                    borderRadius: 0,
                                    fontWeight: 'bold',
                                    mx: '0.5rem'
                                }}
                                key={i}
                                component={Link}
                                to={item.route}
                                variant={item.active ? 'contained' : 'text'}
                                color={item.active ? 'primary' : 'background'}
                                disableElevation
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                            >
                                {item.title}
                            </Button>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    );
};

MdwHeader.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    isCompressed: PropTypes.bool,
    drawerOpened: PropTypes.bool,
    styleHandler: PropTypes.string,
    routeHandler: PropTypes.func,
    routeName: PropTypes.string
};

export default MdwHeader;
