// material-ui
import { Box, Select, Typography, useMediaQuery } from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';
import { lazy } from 'react';

// project imports
import { useNavigate } from 'react-router';
import Loadable from 'ui-component/Loadable';

const IntegrationForm = Loadable(lazy(() => import('ui-component/admin/integrations/IntegrationsForm')));

const CustomSelect = styled(Select)(({ theme }) => ({
    background: theme.palette.background.paper,

    outline: '0 !important',
    height: '2rem',
    borderRadius: '0 !important',
    '> *': {
        borderLeft: 'none !important',
        borderRight: 'none !important',
        borderTop: 'none !important',
        borderBottom: 'none !important',
        borderRadius: '0 !important'
    },
    ':focus': {
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
        border: 0,
        outline: 0,
        borderRadius: '0 !important'
    }
}));

const FormContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'

    // alignItems: 'stretch',
    // width: '100%',
    // maxWidth: '1280px'
    // margin: '1em'
}));
const InputContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.light,
    padding: '1.25rem 1rem 1rem 1rem',
    borderRadius: '.5rem',
    display: 'flex',
    width: 'calc(100% - 2rem)',
    maxWidth: 'calc(100% - 2rem)',
    // flexDirection: 'row',
    alignItems: 'center',
    height: '4rem',
    margin: '1rem'
}));
// ==============================|| SAMPLE PAGE ||============================== //

const AnalyticsIntegration = () => {
    // const { slug } = useParams();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();

    // useEffect(() => {
    //     try {
    //         // console.log('formData', formData);
    //         // console.log('route', matchingRoute);
    //         // console.log(location);
    //         // if (slug && data) {
    //         // setAuthor(data?.authorBySlug);
    //         // setFirstName(data?.authorBySlug.firstName);
    //         // setFirstName(data?.authorBySlug.firstName);
    //         // document.title = `Cieloterra Design — ${data?.authorBySlug.firstName} ${data?.authorBySlug.lastName}`;
    //         // }
    //     } catch (err) {
    //         // console.log(err);
    //     }
    // }, [author]);

    // if (!author || author === undefined || author === null) {
    //     return (
    //         <Box sx={{ width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             <CircularProgress />
    //         </Box>
    //     );
    // }
    return (
        <>
            <Box
                sx={{
                    background: theme.palette.grey[50],
                    zIndex: 100,
                    maxHeight: '9rem',
                    height: '100%',
                    width: '100%',
                    position: 'fixed',
                    // border: '1px solid red',
                    // height: '5rem',
                    p: '2rem',
                    display: 'flex',
                    flexDirection: 'row',

                    alignItems: 'center'
                }}
            >
                <Typography variant="h1" color={theme.palette.primary[200]}>
                    Analytics
                </Typography>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', padding: '.75rem 1rem' }}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h4" color={theme.palette.grey[400]}>
                        <BadgeOutlined /> &nbsp;editor &nbsp;&middot;&nbsp;
                    </Typography>
                </Box> */}
            </Box>

            <Box sx={{ padding: '2rem', display: 'flex', marginTop: '9rem', flexDirection: 'column', alignItems: 'center' }}>
                <Box
                    sx={{
                        maxWidth: '1660px',
                        background: theme.palette.background.paper,
                        borderRadius: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <IntegrationForm integrationType="ga" />
                </Box>
            </Box>

            {/* {content?.map((author) => (
                <AuthorItem key={author._id} author={author} />
            ))} */}
        </>
    );
};

export default AnalyticsIntegration;
