import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import FrontendLayout from 'layout/FrontendLayout';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import EditingLayout from 'layout/EditingLayout';
import NotFoundLayout from 'layout/NotFoundLayout';
import ContainerLayout from 'layout/ContainerLayout';
import AnalyticsIntegration from 'views/admin/analytics';

// ? FRONTEND
const Home = Loadable(lazy(() => import('views/frontend/home')));
const About = Loadable(lazy(() => import('views/frontend/about')));
const ArticleEditor = Loadable(lazy(() => import('views/frontend/article-editor')));
const ArticleCreator = Loadable(lazy(() => import('views/frontend/article-creator')));
// ** // Article
const Article = Loadable(lazy(() => import('views/frontend/article')));
const MdwArticle = Loadable(lazy(() => import('views/frontend/mdwarticle')));

// ** //  Categories
const Editorial = Loadable(lazy(() => import('views/frontend/editorial')));
const Mdw = Loadable(lazy(() => import('views/frontend/mdw')));

// ** //  Redazione
const Redazione = Loadable(lazy(() => import('views/frontend/redazione')));
const Contributor = Loadable(lazy(() => import('views/frontend/contributor')));

// ? Authentication
const AuthLogin3 = Loadable(lazy(() => import('views/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/authentication/authentication3/Register3')));

// ? Admin panel
const DashboardDefault = Loadable(lazy(() => import('views/admin/dashboard')));
const Me = Loadable(lazy(() => import('views/admin/me')));
const ArticleList = Loadable(lazy(() => import('views/admin/article-list')));
const AuthorList = Loadable(lazy(() => import('views/admin/author-list')));
const ContactList = Loadable(lazy(() => import('views/admin/contact-list')));
const EditorialList = Loadable(lazy(() => import('views/admin/editorial-list')));
const ContactDetail = Loadable(lazy(() => import('views/admin/contact')));
const EditorialDetail = Loadable(lazy(() => import('views/admin/editorial')));
const AuthorDetail = Loadable(lazy(() => import('views/admin/author')));
const NewAuthor = Loadable(lazy(() => import('views/admin/newauthor')));
const MediaGallery = Loadable(lazy(() => import('views/admin/media-gallery')));
const SocialIntegration = Loadable(lazy(() => import('views/admin/social')));
const PrivacyIntegration = Loadable(lazy(() => import('views/admin/privacy')));
const NewsletterIntegration = Loadable(lazy(() => import('views/admin/newsletter')));
const HomeLayout = Loadable(lazy(() => import('views/admin/home')));

// ? 404 - No match
const NotFound = Loadable(lazy(() => import('views/frontend/notfound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const FrontendRoutes = {
    path: '/',
    element: <ContainerLayout />,
    children: [
        // ? FRONTEND
        {
            path: '/',
            element: <FrontendLayout />,
            children: [
                // ** // Home
                {
                    path: '/',
                    element: <Home />
                },
                // ** // Article
                {
                    path: '/editorial/:editorial/:id',
                    element: <Article />
                },
                {
                    path: '/editorial/:id',
                    element: <Editorial />
                },

                {
                    path: '/editorial/milan-design-week/:year/:id',
                    element: <MdwArticle />
                },
                {
                    path: '/editorial/milan-design-week/:year',
                    element: <Mdw />
                },
                // ** // About
                {
                    path: '/about',
                    element: <About />
                },
                // ** // Redazione

                {
                    path: '/redazione',
                    element: <Redazione />
                },
                // ** // Autore
                {
                    path: '/redazione/:id',
                    element: <Contributor />
                }
            ]
        },
        // ? EDITOR
        {
            path: '/',
            element: <EditingLayout />,
            children: [
                // {
                //     path: '/',
                //     element: <Home />
                // }
                {
                    path: '/editor/:id',
                    element: <ArticleEditor />
                },
                {
                    path: '/creator',
                    element: <ArticleCreator />
                }
            ]
        },
        // ? AUTHENTICATION
        {
            path: '/ctdadmin',
            element: <MinimalLayout />,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin3 />
                },
                {
                    path: 'register',
                    element: <AuthRegister3 />
                }
            ]
        },
        // ? ADMIN
        {
            path: '/ctdadmin',
            element: <MainLayout />,
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                    // element: <ArticleList />
                },
                {
                    path: 'dashboard',
                    element: <DashboardDefault />
                },
                // * Account
                {
                    path: 'me',
                    element: <Me />
                },
                // * Board
                {
                    path: 'articles',
                    element: <ArticleList />
                },
                {
                    path: 'authors',
                    element: <AuthorList />
                },
                {
                    path: 'media',
                    element: <MediaGallery />
                },
                {
                    path: 'authors/:slug',
                    element: <AuthorDetail />
                },
                {
                    path: 'newauthor',
                    element: <NewAuthor />
                },
                // * Pages
                {
                    path: 'home',
                    element: <HomeLayout />
                },
                {
                    path: 'editorials',
                    element: <EditorialList />
                },
                {
                    path: 'editorials/:id',
                    element: <EditorialDetail />
                },
                // * Integrations
                {
                    path: 'social',
                    element: <SocialIntegration />
                },
                {
                    path: 'privacy',
                    element: <PrivacyIntegration />
                },
                {
                    path: 'analytics',
                    element: <AnalyticsIntegration />
                },
                {
                    path: 'newsletter',
                    element: <NewsletterIntegration />
                },
                {
                    path: 'contacts',
                    element: <ContactList />
                },
                {
                    path: 'contacts/:id',
                    element: <ContactDetail />
                }
            ]
        },
        // ? NOT FOUND
        {
            path: '*',
            element: <NotFoundLayout />,
            children: [
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        }
    ]
};

export default FrontendRoutes;
