import { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports

// import navigation from 'menu-items';
import { SET_ARCHIVE, SET_MENU } from 'store/actions';
import Loadable from 'ui-component/Loadable';

const Archive = Loadable(lazy(() => import('../FrontendLayout/ArchiveMenu')));
const Header = Loadable(lazy(() => import('../FrontendLayout/FrontendHeader')));
const Sidebar = Loadable(lazy(() => import('../FrontendLayout/FullMenu')));

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, route }) => ({
    ...theme.typography.mainContent,
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: '16em',
    height: 'calc(100vh - 16em)',
    ...(!open && {
        opacity: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // marginTop: '66px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen
        }),
        // transition: 'all .5s ease-in-out',

        [theme.breakpoints.up('md')]: {
            // marginLeft: -(frontendDrawerWidth - 20),
            // marginLeft: -frontendDrawerWidth
            // width: `calc(100% - ${frontendDrawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px',
            // width: `calc(100% - ${frontendDrawerWidth}px)`,
            // padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px',
            // width: `calc(100% - ${frontendDrawerWidth}px)`,
            // padding: '16px'
            // ,
            // marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        display: 'none',
        opacity: 0
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const NotFoundLayout = () => {
    const theme = useTheme();
    const route = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    // Handle compressed navbar
    const [isCompressed, setCompressed] = useState(false);
    const [socials, setSocials] = useState(null);

    //! SQNQ: SINE QUA NON QUERIES
    // const { data, error, loading } = useQuery(GET_INTEGRATIONS);
    // console.log('ROUTE', route);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const rightArchiveOpened = useSelector((state) => state.customization.archiveOpened);

    const navHeightHandler = () => {
        if (isCompressed) {
            return '72px';
        }
        if (!isCompressed) {
            return '16em';
        }
        return theme.palette.background.paper;
    };
    const sectionHandler = () => {
        if (route.pathname === '/') {
            return 'none';
        }
        if (!route.pathname === '/' || isCompressed) {
            return 'inline';
        }
        // if (!route.pathname === '/' && !isCompressed) {
        //     return 'none';
        // }
        return 'none';
    };
    const marginHandler = () => {
        if (!matchDownSm) {
            return '16em';
        }
        if (!isCompressed) {
            return '72px';
        }

        return '72px';
    };

    // if (!route.pathname === '/' && !isCompressed) {
    //     return 'none';
    // }

    // const mobileNavbarHandler

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const handleArchiveToggle = () => {
        dispatch({ type: SET_ARCHIVE, archiveOpened: !rightArchiveOpened });
    };

    useEffect(() => {
        const handler = () => {
            setCompressed((isCompressed) => {
                if (!isCompressed && (document.body.scrollTop > 66 || document.documentElement.scrollTop > 66)) {
                    return true;
                }

                if (isCompressed && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
                    return false;
                }

                return isCompressed;
            });
        };

        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
    }, [dispatch]);

    // if (loading || !data || data === null || data === undefined) return <div> </div>;

    return (
        // TODO: watch width
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column'

                // border: '1px solid red'
            }}
        >
            <CssBaseline />
            {/* header */}
            <MobileView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 1000000,
                        width: '100vw',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        height: '72px',
                        boxShadow: leftDrawerOpened || rightArchiveOpened || !isCompressed ? 'none' : '0px 5px 20px rgba(0, 0, 0, .1)',

                        transition: leftDrawerOpened || rightArchiveOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: '72px',
                            bgcolor: 'rgba(0,0,0,0)',

                            // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                            // color: theme.palette.primary.main,
                            // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            vizHandler={!isCompressed}
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={theme.palette.background.paper}
                            isCompressed
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </MobileView>
            <BrowserView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 1000000,
                        height: navHeightHandler,
                        width: '100vw',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        transition: leftDrawerOpened || rightArchiveOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: '72px',
                            bgcolor: 'rgba(0,0,0,0)',
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            vizHandler
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={
                                // route.pathname === '/' || isCompressed || leftDrawerOpened || rightArchiveOpened
                                theme.palette.background.paper
                                // : theme.palette.primary.main
                            }
                            // isCompressed={false}
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </BrowserView>

            {/* drawer */}
            <Archive archiveOpen={rightArchiveOpened} archiveToggle={handleArchiveToggle} />

            <Sidebar socialLinks={socials} drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            {/* archive */}

            {/* main content */}
            <Main
                sx={{
                    // background: 'red',
                    marginTop: marginHandler,
                    ...(leftDrawerOpened &&
                        rightArchiveOpened && {
                            transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                            opacity: 0
                        }),
                    ...(!leftDrawerOpened &&
                        !rightArchiveOpened && {
                            transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                            opacity: 1
                        })
                }}
                theme={theme}
                open={leftDrawerOpened || rightArchiveOpened}
                route={route}
            >
                {/* breadcrumb */}
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
            </Main>

            {/* {socials && <Footer socialLinks={socials} isOverlayOpened={leftDrawerOpened || rightArchiveOpened} />} */}
            {/* <Customization /> */}
        </Box>
    );
};

export default NotFoundLayout;
