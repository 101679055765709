// material-ui
import { useTheme } from '@emotion/react';
import { AddToHomeScreenRounded, Close, InstallDesktopRounded, InstallDesktopSharp } from '@mui/icons-material';
import { Alert, Box, Button, Collapse, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CloseIcon from './icons/CloseIcon';

const Marquee = styled('div')(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    display: 'inline-block',
    '@keyframes marquee': {
        '0%': {
            transform: 'translate3d(0, 0, 0)'
        },
        '100%': {
            transform: 'translate3d(-50%, 0, 0)'
        }
    }
}));

const PWASnackbar = ({ desktop }) => {
    const theme = useTheme();
    const [isApplePrompt, setApplePrompt] = useState(false);
    const [isAndroidPrompt, setAndroidPrompt] = useState(false);
    const [beforeInstallEvent, setBeforeInstallEvent] = useState(null);
    const [installed, setInstalled] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAndroidPrompt(false);
        setApplePrompt(false);
    };

    useEffect(() => {
        if (window !== undefined) {
            const isMacOs = () => navigator.platform.toUpperCase().indexOf('MAC') >= 0;
            const isIos = () =>
                ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

            const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

            if (isIos() && !isInStandaloneMode()) {
                setTimeout(() => {
                    setApplePrompt(true);
                }, 1500);
                // setTimeout(() => {
                //     setApplePrompt(false);
                // }, 20000);
                // this.iosSnackbarActive = true;
            }

            window.addEventListener('beforeinstallprompt', (event) => {
                event.preventDefault();
                setBeforeInstallEvent(event);
                // wait 500ms to show the prompt
                setTimeout(() => {
                    setAndroidPrompt(true);
                }, 1500);

                // the prompt hides after 10 seconds
                // setTimeout(() => {
                //     setAndroidPrompt(false);
                // }, 20000);
            });

            window.addEventListener('appinstalled', (event) => {
                // setInstalled(true);
            });

            window.addEventListener('load', () => {
                if (window.matchMedia('(display-mode: standalone)').matches) {
                    // setInstalled(true);
                }
            });
        }
    }, [beforeInstallEvent, installed]);

    return (
        <Box
            sx={{
                position: 'fixed',
                width: '100%',

                zIndex: 999,
                bottom: 0,
                // left: desktop ? 'auto' : 0,
                left: desktop ? '2rem' : 0,
                pointerEvents: 'none'
            }}
        >
            <Collapse in={isApplePrompt} timeout={1250}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            sx={{ ml: -2 }}
                            onClick={() => {
                                setApplePrompt(false);
                            }}
                        >
                            <CloseIcon iconFill={theme.palette.primary.main} />
                        </IconButton>
                    }
                    sx={{
                        // width: '100vw'
                        padding: '1rem 1.5rem',
                        pointerEvents: 'auto',

                        borderRadius: desktop ? '.875rem .875rem 0 0' : '1.875rem 1.875rem 0 0'
                    }}
                    // onClose={() => setApplePrompt(false)}
                    icon={false}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                        }}
                    >
                        <Box sx={{ width: '20%', maxWidth: '20%' }}>
                            <img
                                src="/favicon/ios/512.png"
                                alt="app-badge"
                                style={{
                                    maxWidth: '3rem',
                                    maxHeight: '3rem',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                        <Box sx={{ width: '80%' }}>
                            <Typography
                                variant="body1"
                                color="primaryDark"
                                sx={{ fontWeight: 500, fontSize: '1rem', lineHeight: '1.5rem', mb: '1.5rem' }}
                            >
                                Installa la web-app di Cieloterra Design per un&apos;esperienza migliore.
                            </Typography>
                            <Typography
                                variant="body1"
                                color="primaryDark"
                                sx={{ fontWeight: 500, fontSize: '1rem', lineHeight: '1.5rem' }}
                            >
                                Fai tap su
                                <img
                                    style={{ margin: '0 .5rem -.5rem .5rem', width: '1.5rem', height: 'auto', objectFit: 'contain' }}
                                    src="/pwa/share.png"
                                    alt="share"
                                />
                                e poi
                                <img
                                    style={{ margin: '0 .5rem -.75rem .5rem', width: '1.5rem', height: 'auto', objectFit: 'contain' }}
                                    src="/pwa/aths.png"
                                    alt="share"
                                />
                                Aggiungi alla Schermata Home
                            </Typography>
                        </Box>
                    </Box>
                </Alert>
            </Collapse>
            <Collapse in={isAndroidPrompt} timeout={1250}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            sx={{ ml: -2 }}
                            onClick={() => {
                                setAndroidPrompt(false);
                            }}
                        >
                            <CloseIcon iconFill={theme.palette.primary.main} />
                        </IconButton>
                    }
                    sx={{
                        pointerEvents: 'auto',

                        // width: '100vw'
                        boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.05)',
                        padding: '1rem 1.5rem .5rem 1.5rem',
                        borderRadius: desktop ? '.875rem .875rem 0 0' : '1.875rem 1.875rem 0 0',
                        maxWidth: '500px'
                    }}
                    // onClose={() => setApplePrompt(false)}
                    icon={false}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                        }}
                    >
                        <Box sx={{ width: '20%', maxWidth: '20%' }}>
                            <img
                                src="/favicon/ios/512.png"
                                alt="app-badge"
                                style={{
                                    maxWidth: '3rem',
                                    maxHeight: '3rem',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'stretch'
                                // border: '1px solid red'
                            }}
                        >
                            <Typography
                                variant="body1"
                                color="primaryDark"
                                sx={{ fontWeight: 500, fontSize: '1rem', lineHeight: '1.5rem' }}
                            >
                                Installa la web-app di Cieloterra Design per un&apos;esperienza migliore.
                            </Typography>
                            <Button
                                variant="text"
                                color="primary"
                                sx={{
                                    mt: '.5rem',
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                    alignSelf: 'flex-end'
                                }}
                                endIcon={desktop ? <InstallDesktopSharp /> : <AddToHomeScreenRounded />}
                                onClick={() => {
                                    setAndroidPrompt(false);

                                    beforeInstallEvent.prompt();
                                    beforeInstallEvent.userChoice.then((choiceResult) => {
                                        if (choiceResult.outcome === 'accepted') {
                                            // setInstalled(true);
                                        }
                                        setBeforeInstallEvent(null);
                                    });
                                }}
                            >
                                Installa
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            </Collapse>
        </Box>
    );
};
PWASnackbar.propTypes = {
    desktop: PropTypes?.bool
    // mobile: PropTypes.bool
};

export default PWASnackbar;
