import { UserContext } from 'context/UserContext';
import { useCallback, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// project imports
// import Customization from '../Customization';
import QSERV_ENDPOINT from '../../constants';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const verifyUser = useCallback(() => {
        fetch(`${QSERV_ENDPOINT}/users/refreshToken`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response && response.ok) {
                    try {
                        console.log(response);
                        const data = await response.json();
                        setUserContext((oldValues) => ({ ...oldValues, token: data.token }));

                        // call refreshToken every 5 minutes to renew the authentication token.
                        // setTimeout(verifyUser, 5 * 60 * 1000);
                        // console.log('verifyUser', userContext);
                        setTimeout(verifyUser, 5 * 60 * 1000);
                    } catch (err) {
                        // console.log('ERR', err);
                    }
                } else if (response && response.status === 401) {
                    setUserContext((oldValues) => ({ ...oldValues, token: null }));
                    // console.log('401', response);
                } else {
                    setUserContext((oldValues) => ({ ...oldValues, token: null }));
                }
                // setTimeout(verifyUser, 5 * 60 * 1000);
            })
            .catch((err) => {
                // siilently drop the error
                // console.log();
                // // navigate('/ctdadmin/login', { replace: true });
                // setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));
                // clearTimeout(verifyUser);
            });
    }, [setUserContext]);
    useEffect(() => {
        if (!userContext.user) {
            verifyUser();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUserContext]);
    return (
        <>
            <Outlet context={{ userContext }} setContext={setUserContext} />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
