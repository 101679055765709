import { useEffect, useState, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { BrowserView, MobileView } from 'react-device-detect';
import { useQuery, gql, useMutation } from '@apollo/client';
import env from 'react-dotenv';
// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './FrontendHeader';
import MdwHeader from './MdwYearHeader';
import Footer from './FrontendFooter';
import Sidebar from './FullMenu';
import Archive from './ArchiveMenu';

import Customization from '../Customization';
// import navigation from 'menu-items';
import { frontendDrawerWidth } from 'store/constant';
import { SET_MENU, SET_ARCHIVE } from 'store/actions';
import { UserContext } from 'context/UserContext';
//                                 margin: '0 1em',
import QSERV_ENDPOINT from '../../constants';

const GET_INTEGRATIONS = gql`
    query integrations {
        integrations {
            _id
            name
            type
            target
        }
    }
`;

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, route }) => ({
    ...theme.typography.mainContent,
    background:
        // route.pathname === '/'
        //     ? `linear-gradient(0deg, ${theme.palette.secondary.bg} 0%, ${theme.palette.primary.main} 100%)`
        //     : theme.palette.background.paper,
        route.pathname === '/' ? `none` : theme.palette.background.paper,
    ...(!open && {
        opacity: 1,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // marginTop: '66px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen
        }),
        // transition: 'all .5s ease-in-out',

        [theme.breakpoints.up('md')]: {
            // marginLeft: -(frontendDrawerWidth - 20),
            // marginLeft: -frontendDrawerWidth
            // width: `calc(100% - ${frontendDrawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px',
            // width: `calc(100% - ${frontendDrawerWidth}px)`,
            // padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px',
            // width: `calc(100% - ${frontendDrawerWidth}px)`,
            // padding: '16px'
            // ,
            // marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        display: 'none',
        opacity: 0
        // overflowY: open ? 'hidden' : 'auto',
        // pointerEvents: 'none',

        // ,
        // marginLeft: 0,
        // borderBottomLeftRadius: 0,
        // borderBottomRightRadius: 0,
        // width: `calc(100% - ${frontendDrawerWidth}px)`
        // ,
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px'
        // }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const EditingLayout = () => {
    const theme = useTheme();
    const route = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    // Handle compressed navbar
    const [isCompressed, setCompressed] = useState(false);
    const [socials, setSocials] = useState(null);
    const [userContext, setUserContext] = useContext(UserContext);
    const navigate = useNavigate();

    //! SQNQ: SINE QUA NON QUERIES
    const { data, error, loading } = useQuery(GET_INTEGRATIONS);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const rightArchiveOpened = useSelector((state) => state.customization.archiveOpened);
    // const leftDrawerClosed = useSelector((state) => state.customization.closed);
    const navbarStyleHandler = () => {
        if (route.pathname === '/' && isCompressed) {
            return theme.palette.primary.main;
        }
        if (route.pathname === '/' && !isCompressed) {
            return 'rgba(0,0,0,0)';
        }
        if (route.pathname !== '/' && isCompressed) {
            return theme.palette.primary.main;
        }
        return theme.palette.background.paper;
    };
    const navHeightHandler = () => {
        if (!matchUpMd && route.pathname.startsWith('/editorial/milan-design-week') && isCompressed) {
            return '120px';
        }
        if (matchUpMd && route.pathname.startsWith('/editorial/milan-design-week') && isCompressed) {
            return '102px';
        }
        if (route.pathname.startsWith('/editorial/milan-design-week') && !isCompressed) {
            return '16em';
        }
        if (!route.pathname === '/editorial/milan-design-week' || isCompressed) {
            return '72px';
        }
        if (!route.pathname === '/editorial/milan-design-week' || !isCompressed) {
            return '16em';
        }
        return '72px';
    };
    const sectionHandler = () => {
        if (route.pathname === '/') {
            return 'none';
        }
        if (!route.pathname === '/' || isCompressed) {
            return 'inline';
        }
        // if (!route.pathname === '/' && !isCompressed) {
        //     return 'none';
        // }
        return 'none';
    };
    const marginHandler = () => {
        if (matchUpMd && isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '102px';
        }
        if (!matchUpMd && isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '120px';
        }
        if (matchUpMd && !isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '16em';
        }
        if (!matchUpMd && !isCompressed && route.pathname.startsWith('/editorial/milan-design-week')) {
            return '120px';
        }
        if (!matchDownSm) {
            return '16em';
        }
        if (!isCompressed) {
            return '72px';
        }

        return '72px';
    };

    // if (!route.pathname === '/' && !isCompressed) {
    //     return 'none';
    // }

    const mobileNavbarHandler = () => {
        if (route.pathname === '/') {
            return theme.palette.primary.main;
        }
        return theme.palette.background.paper;
    };

    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    const handleArchiveToggle = () => {
        dispatch({ type: SET_ARCHIVE, archiveOpened: !rightArchiveOpened });
    };
    const verifyUser = useCallback(() => {
        fetch(`${QSERV_ENDPOINT}/users/refreshToken`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
            if (response.ok) {
                const responseData = await response.json();
                // console.log(responseData);
                setUserContext((oldValues) => ({ ...oldValues, token: responseData.token }));
                // if (data && data.token) {
                if (!userContext.user) {
                    fetch(`${QSERV_ENDPOINT}/users/me`, {
                        method: 'GET',
                        credentials: 'include',
                        // Pass authentication token as bearer token in header
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${responseData.token}`
                        }
                    }).then(async (response) => {
                        if (response.ok) {
                            const meData = await response.json();
                            // console.log('MEDATA', meData);

                            setUserContext((oldVals) => ({ ...oldVals, user: meData }));

                            // if (me) {
                            //     setUserContext((oldVals) => ({ ...oldVals }));
                            // }
                            // setMe(meData);
                            // if (data) setMe(data?.authorById);

                            // fetch author
                        } else {
                            navigate('/ctdadmin/login', { replace: true });
                            setUserContext((oldVals) => ({ ...oldVals, token: null, user: null }));
                        }
                    });
                }

                // }
            } else {
                // if (userContext.token === null) {
                navigate('/ctdadmin/login', { replace: true });
                setUserContext((oldValues) => ({ ...oldValues, token: null, user: null }));

                // }
            }
            setTimeout(verifyUser, 5 * 60 * 1000);
        });
    }, [setUserContext, navigate, userContext.user]);

    useEffect(() => {
        if (!userContext.user) {
            verifyUser();
        }
        if (data) {
            setSocials(data?.integrations);
            dispatch({ type: SET_MENU, opened: false });
            dispatch({ type: SET_ARCHIVE, archiveOpened: false });
            // console.log(socials);
            // setFirstName(data?.authorBySlug.firstName);
            // setFirstName(data?.authorBySlug.firstName);
            // document.title = `Cieloterra Design — ${data?.authorBySlug.firstName} ${data?.authorBySlug.lastName}`;
        }
        const handler = () => {
            setCompressed((isCompressed) => {
                if (!isCompressed && (document.body.scrollTop > 66 || document.documentElement.scrollTop > 66)) {
                    return true;
                }

                if (isCompressed && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
                    return false;
                }

                return isCompressed;
            });
        };

        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
    }, [data, dispatch, verifyUser, userContext.user]);

    if (loading || !data || data === null || data === undefined) return <div> </div>;

    return (
        // TODO: watch width
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column'

                // border: '1px solid red'
            }}
        >
            <CssBaseline />
            {/* header */}
            <MobileView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 1000000,
                        width: '100vw',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        height: '72px',
                        boxShadow: leftDrawerOpened || rightArchiveOpened || !isCompressed ? 'none' : '0px 5px 20px rgba(0, 0, 0, .1)',

                        transition: leftDrawerOpened || rightArchiveOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: '72px',
                            bgcolor: leftDrawerOpened || rightArchiveOpened ? 'rgba(0,0,0,0)' : mobileNavbarHandler,

                            // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                            // color: theme.palette.primary.main,
                            // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            vizHandler={route.pathname === '/' || !isCompressed}
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={
                                route.pathname === '/' || leftDrawerOpened || rightArchiveOpened
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main
                            }
                            isCompressed
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                    <Toolbar
                        sx={{
                            p: 0,
                            minHeight: '3rem !important',
                            maxHeight: '3rem !important',
                            height: '3rem !important',
                            bgcolor: theme.palette.secondary.bg,
                            transition: 'all .3s ease-in-out',
                            display:
                                route.pathname.startsWith('/editorial/milan-design-week') && !leftDrawerOpened && !rightArchiveOpened
                                    ? 'block'
                                    : 'none'
                        }}
                    >
                        <MdwHeader
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={theme.palette.background.paper}
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </MobileView>
            <BrowserView>
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    // position={isCompressed ? 'fixed' : 'relative'}
                    color="inherit"
                    elevation={0}
                    sx={{
                        zIndex: 1000000,
                        height: navHeightHandler,
                        width: '100vw',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,0)',
                        // overflowX: 'hidden',,
                        transition: leftDrawerOpened || rightArchiveOpened ? theme.transitions.create('width') : 'none',
                        boxShadow: isCompressed ? '0px 5px 20px rgba(0, 0, 0, .1)' : 'none'
                    }}
                >
                    <Toolbar
                        sx={{
                            minHeight: '72px',
                            bgcolor: leftDrawerOpened || rightArchiveOpened ? 'rgba(0,0,0,0)' : navbarStyleHandler,
                            transition: 'all .3s ease-in-out'
                        }}
                    >
                        <Header
                            vizHandler
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={
                                route.pathname === '/' || isCompressed || leftDrawerOpened || rightArchiveOpened
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main
                            }
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                    <Toolbar
                        sx={{
                            py: 0,
                            minHeight: matchUpMd ? '1.875rem !important' : '3rem !important',
                            maxHeight: matchUpMd ? '1.875rem !important' : '3rem !important',
                            height: matchUpMd ? '1.875rem !important' : '3rem !important',
                            bgcolor: theme.palette.secondary.bg,
                            transition: 'all .3s ease-in-out',
                            display:
                                route.pathname.startsWith('/editorial/milan-design-week') && !leftDrawerOpened && !rightArchiveOpened
                                    ? 'block'
                                    : 'none'
                        }}
                    >
                        <MdwHeader
                            routeHandler={sectionHandler}
                            drawerOpened={leftDrawerOpened}
                            archiveOpened={rightArchiveOpened}
                            styleHandler={theme.palette.background.paper}
                            isCompressed={leftDrawerOpened || rightArchiveOpened ? true : isCompressed}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                            handleArchiveToggle={handleArchiveToggle}
                        />
                    </Toolbar>
                </AppBar>
            </BrowserView>

            {/* drawer */}
            <Archive archiveOpen={rightArchiveOpened} archiveToggle={handleArchiveToggle} />

            {socials && <Sidebar socialLinks={socials} drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />}
            {/* archive */}

            {/* main content */}
            <Main
                sx={{
                    // background: 'red',
                    marginTop: marginHandler,
                    ...(leftDrawerOpened &&
                        rightArchiveOpened && {
                            transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                            opacity: 0
                        }),
                    ...(!leftDrawerOpened &&
                        !rightArchiveOpened && {
                            transition: theme.transitions.create('opacity', {
                                easing: theme.transitions.easing.easeOut,
                                duration: theme.transitions.duration.enteringScreen
                            }),
                            opacity: 1
                        })
                }}
                theme={theme}
                open={leftDrawerOpened || rightArchiveOpened}
                route={route}
            >
                {/* breadcrumb */}
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet context={[userContext, setUserContext]} />
            </Main>

            {/* {socials && <Footer socialLinks={socials} isOverlayOpened={leftDrawerOpened || rightArchiveOpened} />} */}
            {/* <Customization /> */}
        </Box>
    );
};

export default EditingLayout;
