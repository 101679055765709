import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Box, Typography, Button, ButtonBase } from '@mui/material';
import FacebookIcon from 'ui-component/icons/brands/FacebookIcon';
import InstagramIcon from 'ui-component/icons/brands/InstagramIcon';

// project imports
import NavItem from '../NavItem';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { MENU_OPEN, SET_MENU, ARCHIVE_OPEN, SET_ARCHIVE } from 'store/actions';
import Iubenda from 'react-iubenda-policy';
import { useEffect, useState } from 'react';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, socials }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [socialLinks, setSocialLinks] = useState(null);
    const [email, setEmail] = useState(null);
    const [fb, setFb] = useState(null);
    const [ig, setIg] = useState(null);
    const [policyID, setPolicyID] = useState(null);

    const itemHandler = (id) => {
        // dispatch({ type: MENU_OPEN, id });
        dispatch({ type: SET_MENU, id, opened: false });
    };
    const archiveHandler = (id) => {
        dispatch({ type: SET_MENU, id, opened: false });
        dispatch({ type: SET_ARCHIVE, id, archiveOpened: true });
    };
    useEffect(() => {
        if (socials) {
            // console.log(socialLinks);
            setSocialLinks(socials);

            // const emailTarget = socialLinks?.filter((social) => social?.type === 'email')[0];
            const emailTarget = socialLinks?.find((social) => social?.type === 'email');
            const fbTarget = socialLinks?.find((social) => social?.type === 'fb');
            const igTarget = socialLinks?.find((social) => social?.type === 'ig');
            const iuTarget = socialLinks?.find((social) => social?.type === 'iu');

            // console.log(emailTarget);
            if (emailTarget) {
                setEmail(emailTarget);
            }
            if (fbTarget) {
                setFb(fbTarget);
            }
            if (emailTarget) {
                setEmail(emailTarget);
            }
            if (igTarget) {
                setIg(igTarget);
            }
            // c
            if (igTarget) {
                setIg(igTarget);
            }
            if (iuTarget) {
                setPolicyID(iuTarget?.target);
            }
            // console.log(fb);
            // console.log(ig);
            // console.log(email);

            // }
        }
    }, [socialLinks, socials]);
    // console.log('NAVGROUP', fb);
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            // case 'collapse':
            //     return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} fbTarget={fb} igTarget={ig} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    if (email === null || fb === null || ig === null) return <div />;
    return (
        <>
            <BrowserView>
                {/* <Typography variant="h1" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom> */}

                <Box
                    sx={{
                        // width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                        height: '10vh',
                        // justifyContent: 'flex-start',
                        margin: '0 auto',
                        maxWidth: '1280px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            bottom: '0',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        {items}
                        <Button
                            disableRipple
                            sx={{
                                // borderRadius: `${customization.borderRadius}px`,
                                borderRadius: 0,
                                // mx: '.5em',
                                // alignItems: 'flex',
                                // backgroundColor: level > 1 ? 'transparent !important' : 'inherit'
                                // py: level > 1 ? 1 : 1.25
                                // pl: `${level * 24}px`
                                minWidth: '5rem'
                            }}
                            onClick={() => archiveHandler(item.id)}
                        >
                            <Typography
                                sx={{ fontSize: '5rem' }}
                                // variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'activeTopicItem' : 'topicItem'}
                                variant="activeTopicItem"
                                // color="inherit"
                            >
                                Archivio
                            </Typography>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            // width: '100%',
                            mx: '1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography
                                onClick={(e) => {
                                    window.location.href = `mailto:${email?.target}@cieloterradesign.com?subject=Contatto da cieloterradesign.com`;
                                    e.preventDefault();
                                }}
                                variant="footerRef"
                                sx={{ cursor: 'pointer' }}
                            >
                                {email?.target}@cieloterradesign.com
                            </Typography>
                        </Box>
                        <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="footerRef">
                                CIELOTERRA WEBMAGAZINE | &copy; {new Date().getFullYear()}. All rights reserved.
                            </Typography>
                        </Box>
                        <Box sx={{ width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Iubenda id={policyID} type="privacy" styling="nostyle">
                                <Typography onClick={() => itemHandler(item.id)} variant="footerRef">
                                    COOKIES &amp; PRIVACY POLICY
                                </Typography>
                            </Iubenda>
                        </Box>
                    </Box>
                </Box>
                {/* </Box> */}
            </BrowserView>

            <MobileView>
                <Box
                    sx={{
                        // width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        m: '0 1rem'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            margin: '0',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        {items}
                        <Button
                            disableRipple
                            sx={{
                                // borderRadius: `${customization.borderRadius}px`,
                                borderRadius: 0,
                                // mx: '.5em',
                                // alignItems: 'flex',
                                // backgroundColor: level > 1 ? 'transparent !important' : 'inherit'
                                // py: level > 1 ? 1 : 1.25
                                // pl: `${level * 24}px`
                                minWidth: '5rem'
                            }}
                            onClick={() => archiveHandler(item.id)}
                        >
                            <Typography
                                sx={{ fontSize: '5rem' }}
                                // variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'activeTopicItem' : 'topicItem'}
                                variant="activeTopicItem"
                                // color="inherit"
                            >
                                Archivio
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ mx: '1.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 'calc(100vw/2)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Typography
                            onClick={(e) => {
                                window.location.href = `mailto:${email?.target}@cieloterradesign.com?subject=Contatto da cieloterradesign.com`;
                                e.preventDefault();
                            }}
                            variant="footerRef"
                            sx={{ cursor: 'pointer' }}
                        >
                            {email?.target}@cieloterradesign.com
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 'calc(100vw/2)',

                            // padding: '1em 0',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            href={`https://fb.me/${fb?.target}`}
                            target="_blank"
                            disableRipple
                            variant="text"
                            color="background"
                            sx={{
                                padding: 0,
                                // top: '1rem',
                                minWidth: 'auto',
                                width: '1rem',
                                height: '1rem',
                                mx: '.5rem',
                                borderRadius: '5rem'
                                // aspectRatio: '1/1'
                            }}
                        >
                            <FacebookIcon iconFill={theme.palette.background.paper} />
                        </Button>
                        <Button
                            href={`https://instagram.com/${fb?.target}`}
                            target="_blank"
                            disableRipple
                            variant="text"
                            color="background"
                            sx={{
                                padding: 0,
                                // top: '1rem',
                                minWidth: 'auto',
                                width: '1rem',
                                height: '1rem',
                                ml: '.5rem',
                                borderRadius: '5rem'
                                // aspectRatio: '1/1'
                            }}
                        >
                            <InstagramIcon iconFill={theme.palette.background.paper} />
                        </Button>
                    </Box>
                </Box>
            </MobileView>
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object,
    socials: PropTypes?.array
};

export default NavGroup;
