import { Outlet } from 'react-router-dom';
// import { useSpring } from 'react-spring';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const ContainerLayout = () => <Outlet />;

export default ContainerLayout;
