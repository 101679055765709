// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const FilterIcon = ({ iconFill, size }) => {
    const theme = useTheme();

    return (
        <svg
            style={{ transform: `scale(${size})` }}
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={iconFill}
                d="M23.25 16.5H7.5V15.75C7.5 15.3375 7.1625 15 6.75 15H5.25C4.8375 15 4.5 15.3375 4.5 15.75V16.5H0.75C0.3375 16.5 0 16.8375 0 17.25V18.75C0 19.1625 0.3375 19.5 0.75 19.5H4.5V20.25C4.5 20.6625 4.8375 21 5.25 21H6.75C7.1625 21 7.5 20.6625 7.5 20.25V19.5H23.25C23.6625 19.5 24 19.1625 24 18.75V17.25C24 16.8375 23.6625 16.5 23.25 16.5ZM23.25 9H19.5V8.25C19.5 7.8375 19.1625 7.5 18.75 7.5H17.25C16.8375 7.5 16.5 7.8375 16.5 8.25V9H0.75C0.3375 9 0 9.3375 0 9.75V11.25C0 11.6625 0.3375 12 0.75 12H16.5V12.75C16.5 13.1625 16.8375 13.5 17.25 13.5H18.75C19.1625 13.5 19.5 13.1625 19.5 12.75V12H23.25C23.6625 12 24 11.6625 24 11.25V9.75C24 9.3375 23.6625 9 23.25 9ZM23.25 1.5H13.5V0.75C13.5 0.3375 13.1625 0 12.75 0H11.25C10.8375 0 10.5 0.3375 10.5 0.75V1.5H0.75C0.3375 1.5 0 1.8375 0 2.25V3.75C0 4.1625 0.3375 4.5 0.75 4.5H10.5V5.25C10.5 5.6625 10.8375 6 11.25 6H12.75C13.1625 6 13.5 5.6625 13.5 5.25V4.5H23.25C23.6625 4.5 24 4.1625 24 3.75V2.25C24 1.8375 23.6625 1.5 23.25 1.5Z"
            />
        </svg>
    );
};
FilterIcon.propTypes = {
    iconFill: PropTypes.string,
    size: PropTypes?.string
};

export default FilterIcon;
