import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import NavigationScroll from 'layout/NavigationScroll';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography, Button, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MenuIcon from 'ui-component/icons/MenuIcon';
import SearchIcon from 'ui-component/icons/SearchIcon';
import CloseIcon from 'ui-component/icons/CloseIcon';
import { SET_MENU, SET_ARCHIVE } from 'store/actions';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({
    handleLeftDrawerToggle,
    handleArchiveToggle,
    isCompressed,
    styleHandler,
    routeHandler,
    drawerOpened,
    archiveOpened,
    // routeName,
    vizHandler
}) => {
    const theme = useTheme();
    const route = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const itemHandler = () => {
        // dispatch({ type: MENU_OPEN, id });

        dispatch({ type: SET_MENU, opened: false });
        dispatch({ type: SET_ARCHIVE, archiveOpened: false });
    };
    const subRouteHandler = () => {
        // if (route.pathname === '/') {
        //     navigate('/editorial/design-designers');
        if (route.pathname.startsWith('/editorial/design-designers')) {
            navigate('/editorial/design-designers');
        } else if (route.pathname.startsWith('/editorial/architettura')) {
            navigate('/editorial/architettura');
        } else if (route.pathname.startsWith('/editorial/ho-visto-case')) {
            navigate('/editorial/ho-visto-case');
        } else if (route.pathname.startsWith('/editorial/envisuel')) {
            navigate('/editorial/envisuel');
        } else if (route.pathname.startsWith('/editorial/manuale-di-stili')) {
            navigate('/editorial/manuale-di-stili');
        } else if (route.pathname.startsWith('/editorial/coolture')) {
            navigate('/editorial/coolture');
        } else if (route.pathname.startsWith('/editorial/milan-design-week')) {
            navigate('/editorial/milan-design-week/2024');
        } else if (route.pathname.startsWith('/redazione/')) {
            navigate('/redazione');
        }
    };

    const [prettyRoute, setPrettyRoute] = useState('');

    const updateHeaderTitle = useCallback(() => {
        if (route.pathname === '/') {
            setPrettyRoute('');
        } else if (route.pathname.startsWith('/editorial/design-designers')) {
            setPrettyRoute('Design & Designers');
        } else if (route.pathname.startsWith('/editorial/architettura')) {
            setPrettyRoute('Architettura');
        } else if (route.pathname.startsWith('/editorial/ho-visto-case')) {
            setPrettyRoute('Ho visto case');
        } else if (route.pathname.startsWith('/editorial/envisuel')) {
            setPrettyRoute('Envisuel');
        } else if (route.pathname.startsWith('/editorial/manuale-di-stili')) {
            setPrettyRoute('Manuale di stili');
        } else if (route.pathname.startsWith('/editorial/coolture')) {
            setPrettyRoute('Coolture');
        } else if (route.pathname.startsWith('/editorial/milan-design-week')) {
            setPrettyRoute('Milan Design Week');
        } else if (route.pathname.startsWith('/article')) {
            setPrettyRoute('Articolo');
        } else if (route.pathname === '/about') {
            setPrettyRoute('About');
        } else if (route.pathname.startsWith('/redazione')) {
            setPrettyRoute('Redazione');
        } else if (route.pathname === '/archivio') {
            setPrettyRoute('Archivio');
        }
    }, [route.pathname]);

    useEffect(() => {
        updateHeaderTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.pathname]);

    return (
        <>
            <Box
                sx={{
                    // width: 228,
                    display: 'flex',
                    width: '100%',
                    margin: '0 auto',
                    height: isCompressed ? 'initial' : '16em',
                    // alignItems: '',

                    justifyContent: 'space-between',
                    [theme.breakpoints.up('md')]: {
                        maxWidth: '1280px'
                    }
                }}
            >
                <Box
                    display={drawerOpened || archiveOpened ? 'none' : 'flex'}
                    sx={{
                        // width: 228,
                        // display: drawerOpened ? 'none' : 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <ButtonBase onClick={handleLeftDrawerToggle} disableRipple sx={{ overflow: 'hidden' }}>
                        <Avatar
                            // variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: 'none'

                                // '&:hover': {
                                //     background: isCompressed ? theme.palette.background.paper : theme.palette.primary.main,
                                //     color: isCompressed ? theme.palette.primary.main : theme.palette.background.paper
                                // }
                            }}
                        >
                            <MenuIcon iconFill={styleHandler} />
                            {/* <MenuIcon iconFill={isCompressed ? theme.palette.background.paper : theme.palette.primary.main} /> */}
                        </Avatar>
                    </ButtonBase>
                </Box>
                <Box sx={{ display: isCompressed ? 'flex' : 'flex', alignItems: 'center' }}>
                    {!matchUpMd && (
                        <Button
                            onClick={() => {
                                itemHandler();
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                });
                            }}
                            sx={{ display: vizHandler ? 'flex' : 'none' }}
                        >
                            <LogoSection logoFill={styleHandler} compressed={isCompressed} displayHandler={vizHandler} />
                        </Button>
                    )}
                    {!matchUpMd && isCompressed && route.pathname !== '/' && (
                        <>
                            <Button
                                onClick={() => {
                                    subRouteHandler();

                                    // itemHandler();

                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }}
                                disableElevation
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                variant="text"
                                color="background"
                                sx={{ margin: '0 0 0 0', padding: 0, height: '6rem', display: routeHandler }}
                            >
                                <Typography
                                    sx={{
                                        '&:first-letter': {
                                            textTransform: 'uppercase'
                                        }
                                    }}
                                    color={theme.palette.primary.main}
                                    to={prettyRoute}
                                    variant="navbarSection"
                                >
                                    {prettyRoute}
                                </Typography>
                            </Button>
                        </>
                    )}
                    {matchUpMd && (
                        <>
                            <Button
                                sx={{ p: 0 }}
                                onClick={() => {
                                    itemHandler();
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }}
                            >
                                <LogoSection displayHandler={vizHandler} logoFill={styleHandler} compressed={isCompressed} />
                            </Button>
                        </>
                    )}
                </Box>
                <Box
                    display={drawerOpened || archiveOpened ? 'none' : 'flex'}
                    sx={{
                        // width: 228,
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <ButtonBase onClick={handleArchiveToggle} disableRipple sx={{ borderRadius: '3em', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: 'none'
                            }}
                            color="inherit"
                        >
                            <SearchIcon iconFill={styleHandler} />
                        </Avatar>
                    </ButtonBase>
                </Box>
                <Box
                    sx={{
                        display: drawerOpened ? 'flex' : 'none',
                        position: 'absolute',
                        right: 5,
                        top: 0,
                        width: '72px',
                        height: '72px',
                        // bgcolor: 'red',
                        // width: 228,
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Button
                        disableRipple
                        onClick={handleLeftDrawerToggle}
                        sx={{ borderRadius: '0', width: '72px', height: '72px', bgcolor: theme.palette.primary.main }}
                    >
                        <Avatar
                            // variant=""
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: 'none'
                            }}
                            color="inherit"
                        >
                            <CloseIcon iconFill={styleHandler} />
                        </Avatar>
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: archiveOpened ? 'flex' : 'none',
                        position: 'absolute',
                        right: 5,
                        top: 0,
                        width: '72px',
                        height: '72px',
                        // bgcolor: 'red',
                        // width: 228,
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Button
                        disableRipple
                        onClick={handleArchiveToggle}
                        sx={{ borderRadius: '0', width: '72px', height: '72px', bgcolor: theme.palette.primary.main }}
                    >
                        <Avatar
                            // variant=""
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: 'none'
                            }}
                            color="inherit"
                        >
                            <CloseIcon iconFill={styleHandler} />
                        </Avatar>
                    </Button>
                </Box>
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    handleArchiveToggle: PropTypes.func,
    isCompressed: PropTypes.bool,
    drawerOpened: PropTypes.bool,
    archiveOpened: PropTypes.bool,
    styleHandler: PropTypes.string,
    routeHandler: PropTypes.func,
    // routeName: PropTypes.string,
    vizHandler: PropTypes.bool
};

export default Header;
