// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const ExpandedLogo = ({ color }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg width="68" height="113" viewBox="0 0 68 113" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M0.75 54.625C0.25 54.125 0 53.5 0 52.7708V2.6875C0 1.95833 0.25 1.3125 0.75 0.791667C1.25 0.270833 1.875 0 2.60417 0H8.9375C9.66667 0 10.2917 0.270833 10.7917 0.791667C11.2917 1.3125 11.5417 1.95833 11.5417 2.6875V27.2083H6.25V5.29167H5.29167V50.0625H6.25V28.1667H11.5417V52.7708C11.5417 53.5 11.2917 54.125 10.7917 54.625C10.2917 55.125 9.66667 55.375 8.9375 55.375H2.60417C1.875 55.375 1.25 55.125 0.75 54.625Z"
                    fill={color}
                />
                <path d="M12.5 0H17.7917V55.375H12.5V0Z" fill={color} />
                <path
                    d="M18.75 55.375V0H30.2917V24.0417H25V5.29167H24.0417V25H30.2917V30.2917H24.0417V50.0625H25V31.3125H30.2917V55.3542H18.75V55.375Z"
                    fill={color}
                />
                <path d="M31.25 55.375V0H36.5417V50.0625H37.5V28.1667H42.7917V55.375H31.25V55.375Z" fill={color} />
                <path
                    d="M46.3542 55.375C45.625 55.375 45 55.125 44.5 54.625C44 54.125 43.75 53.5 43.75 52.7708V2.60417C43.75 1.875 44 1.25 44.5 0.75C45 0.25 45.625 0 46.3542 0H52.6875C53.4167 0 54.0417 0.25 54.5417 0.75C55.0417 1.25 55.2917 1.875 55.2917 2.60417V52.75C55.2917 53.4792 55.0417 54.1042 54.5417 54.6042C54.0417 55.1042 53.4167 55.3542 52.6875 55.3542H46.3542V55.375ZM49.0417 50.0625H50V5.29167H49.0417V50.0625Z"
                    fill={color}
                />
                <path d="M6.25 112.5V62.4375H5.29167V84.3542H0V57.125H17.7917V84.3333H12.5V62.4375H11.5417V112.5H6.25Z" fill={color} />
                <path
                    d="M18.75 112.5V57.125H30.2917V81.1667H25V62.4167H24.0417V82.1042H30.2917V87.3958H24.0417V107.167H25V88.4167H30.2917V112.5H18.75Z"
                    fill={color}
                />
                <path
                    d="M42.7917 112.5H37.5V90.5833H36.5417V112.5H31.25V57.125H40.1875C40.9167 57.125 41.5417 57.375 42.0417 57.875C42.5417 58.375 42.7917 59 42.7917 59.7292V81.6458C42.7917 82.375 42.5417 83.0208 42.0417 83.5417C41.5417 84.0625 40.9167 84.3333 40.1875 84.3333H36.5417V85.2917H40.1875C40.9167 85.2917 41.5417 85.5625 42.0417 86.0833C42.5417 86.6042 42.7917 87.25 42.7917 87.9792V112.5ZM36.5417 79.0417H37.5V62.4375H36.5417V79.0417Z"
                    fill={color}
                />
                <path
                    d="M55.2917 112.5H50V90.5833H49.0417V112.5H43.75V57.125H52.6875C53.4167 57.125 54.0417 57.375 54.5417 57.875C55.0417 58.375 55.2917 59 55.2917 59.7292V81.6458C55.2917 82.375 55.0417 83.0208 54.5417 83.5417C54.0417 84.0625 53.4167 84.3333 52.6875 84.3333H49.0417V85.2917H52.6875C53.4167 85.2917 54.0417 85.5625 54.5417 86.0833C55.0417 86.6042 55.2917 87.25 55.2917 87.9792V112.5V112.5ZM49.0417 79.0417H50V62.4375H49.0417V79.0417Z"
                    fill={color}
                />
                <path
                    d="M65.1665 57.125C65.8957 57.125 66.5207 57.3958 67.0207 57.9167C67.5207 58.4375 67.7707 59.0833 67.7707 59.8125V82.2083V87.5V112.5H62.479V87.5H61.5207V112.5H56.229V87.5V82.2083V59.8125C56.229 59.0833 56.479 58.4375 56.979 57.9167C57.479 57.3958 58.104 57.125 58.8332 57.125H65.1665ZM62.479 82.2083V62.4375H61.5207V82.2083H62.479Z"
                    fill={color}
                />
                <path
                    d="M67.3218 50H63.4321V48.6702C63.4321 48.371 63.4654 48.1383 63.4986 47.9721C63.5651 47.7394 63.6316 47.5732 63.7646 47.4069C63.9308 47.2075 64.1635 47.0413 64.4295 46.9415C64.6954 46.8418 64.9946 46.7753 65.3603 46.7753C65.6595 46.7753 65.9255 46.8085 66.1582 46.875C66.3909 46.9415 66.5904 47.0413 66.7233 47.141C66.8563 47.2407 66.9893 47.3737 67.089 47.5067C67.1888 47.6397 67.2553 47.8059 67.2885 47.9721C67.3218 48.1383 67.355 48.371 67.355 48.6037V50H67.3218ZM66.8563 49.4681V48.637C66.8563 48.371 66.8231 48.1716 66.7898 48.0386C66.7566 47.9056 66.6901 47.7726 66.5904 47.7061C66.4574 47.5732 66.2912 47.4734 66.0917 47.4069C65.8922 47.3405 65.6263 47.3072 65.3271 47.3072C64.9281 47.3072 64.5957 47.3737 64.3962 47.5067C64.1635 47.6397 64.0305 47.8059 63.964 48.0053C63.8976 48.1383 63.8976 48.371 63.8976 48.6702V49.5013H66.8563V49.4681Z"
                    fill={color}
                />
                <path
                    d="M67.3218 46.0439H63.4321V43.2181H63.8976V45.512H65.0944V43.3511H65.5598V45.512H66.8896V43.1183H67.355V46.0439H67.3218Z"
                    fill={color}
                />
                <path
                    d="M66.0919 42.6197L66.0586 42.121C66.2581 42.0878 66.4243 42.0545 66.5241 41.9548C66.657 41.855 66.7568 41.7553 66.8233 41.5558C66.8897 41.3896 66.923 41.1901 66.923 40.9574C66.923 40.758 66.8897 40.5917 66.8233 40.4255C66.7568 40.2593 66.6903 40.1596 66.5905 40.0931C66.4908 40.0266 66.3911 39.9933 66.2581 39.9933C66.1251 39.9933 66.0254 40.0266 65.9256 40.0931C65.8259 40.1596 65.7594 40.2925 65.6929 40.4588C65.6597 40.5585 65.5932 40.7912 65.4935 41.1569C65.3937 41.5226 65.3272 41.7553 65.2607 41.9215C65.161 42.121 65.028 42.254 64.8951 42.3205C64.7621 42.4202 64.5959 42.4534 64.3964 42.4534C64.1969 42.4534 64.0307 42.387 63.8312 42.2872C63.6318 42.1875 63.532 42.0213 63.4323 41.7886C63.3326 41.5558 63.2993 41.3231 63.2993 41.0904C63.2993 40.7912 63.3326 40.5585 63.4323 40.3258C63.532 40.0931 63.665 39.9269 63.8312 39.8271C63.9975 39.7274 64.2302 39.6609 64.4296 39.6276L64.4629 40.1263C64.2302 40.1596 64.0307 40.2261 63.8977 40.3923C63.7647 40.5585 63.6983 40.758 63.6983 41.0572C63.6983 41.3564 63.7647 41.5891 63.8645 41.7221C63.9642 41.855 64.1304 41.9215 64.2634 41.9215C64.3964 41.9215 64.5294 41.855 64.5959 41.7553C64.6956 41.6556 64.7621 41.3896 64.8618 40.9907C64.9616 40.5585 65.028 40.2925 65.0945 40.1263C65.1943 39.8936 65.3272 39.7274 65.4935 39.6276C65.6597 39.5279 65.8259 39.4614 66.0586 39.4614C66.2581 39.4614 66.4576 39.5279 66.657 39.6276C66.8565 39.7606 66.9895 39.9269 67.0892 40.1263C67.1889 40.359 67.2554 40.5917 67.2554 40.8909C67.2554 41.2566 67.1889 41.5226 67.0892 41.7886C66.9895 42.0213 66.8233 42.2207 66.6238 42.3537C66.5573 42.5532 66.3246 42.6197 66.0919 42.6197Z"
                    fill={color}
                />
                <path d="M67.3218 38.7301H63.4321V38.2314H67.3218V38.7301Z" fill={color} />
                <path
                    d="M65.7926 35.5053H65.3271V33.8431H66.7567C66.9561 34.109 67.1223 34.3418 67.2221 34.641C67.3218 34.9069 67.3883 35.1729 67.3883 35.4721C67.3883 35.8378 67.3218 36.2035 67.1556 36.5027C66.9894 36.8019 66.7567 37.0346 66.4574 37.2008C66.1582 37.367 65.7926 37.4335 65.4269 37.4335C65.0612 37.4335 64.6955 37.367 64.363 37.2008C64.0306 37.0346 63.7979 36.8019 63.6316 36.5027C63.4654 36.2035 63.3989 35.871 63.3989 35.4721C63.3989 35.2061 63.4322 34.9402 63.5319 34.7074C63.6316 34.4747 63.7646 34.3085 63.8976 34.1755C64.0638 34.0426 64.2633 33.9428 64.5293 33.8763L64.6622 34.3418C64.4628 34.4082 64.2965 34.4747 64.1968 34.5745C64.0971 34.6742 63.9973 34.7739 63.9308 34.9402C63.8644 35.1064 63.8311 35.2726 63.8311 35.4721C63.8311 35.7048 63.8644 35.9043 63.9308 36.0705C63.9973 36.2367 64.0971 36.3697 64.1968 36.4694C64.3298 36.5691 64.4295 36.6689 64.5957 36.7021C64.8285 36.8019 65.0944 36.8351 65.3936 36.8351C65.7261 36.8351 66.0253 36.7686 66.258 36.6689C66.4907 36.5359 66.6569 36.3697 66.7899 36.137C66.8896 35.9043 66.9561 35.6715 66.9561 35.4056C66.9561 35.1729 66.9229 34.9734 66.8231 34.7407C66.7234 34.5412 66.6569 34.375 66.5572 34.242H65.7926V35.5053Z"
                    fill={color}
                />
                <path
                    d="M67.3218 33.0785H63.4321V32.5465L66.4906 30.5186H63.4321V30.0199H67.3218V30.5519L64.2632 32.5798H67.3218V33.0785Z"
                    fill={color}
                />
                <path
                    d="M67.3218 27.6596H63.4321V26.895L66.1914 25.9641C66.4574 25.8644 66.6236 25.8311 66.7566 25.7646C66.6236 25.7314 66.3909 25.6649 66.1249 25.5652L63.4321 24.6343V23.9362H67.3218V24.4348H64.0638L67.3218 25.5652V26.0306L63.9973 27.1609H67.3218V27.6596Z"
                    fill={color}
                />
                <path
                    d="M67.3218 23.5705L63.4321 22.0745V21.5093L67.3218 19.9136V20.512L66.1582 20.9774V22.6064L67.3218 23.0386V23.5705ZM65.726 22.4402V21.1104L64.6622 21.5093C64.3297 21.6423 64.0638 21.7088 63.8643 21.7753C64.097 21.8085 64.363 21.9083 64.5957 21.9748L65.726 22.4402Z"
                    fill={color}
                />
                <path
                    d="M65.7926 17.6862H65.3271V16.0239H66.7567C66.9561 16.2899 67.1223 16.5226 67.2221 16.8218C67.3218 17.121 67.3883 17.3537 67.3883 17.6529C67.3883 18.0186 67.3218 18.3843 67.1556 18.6835C66.9894 18.9827 66.7567 19.2154 66.4574 19.3817C66.1582 19.5479 65.7926 19.6144 65.4269 19.6144C65.0612 19.6144 64.6955 19.5479 64.363 19.3817C64.0306 19.2154 63.7979 18.9827 63.6316 18.6835C63.4654 18.3843 63.3989 18.0519 63.3989 17.6529C63.3989 17.387 63.4322 17.121 63.5319 16.8883C63.6316 16.6556 63.7646 16.4894 63.8976 16.3564C64.0306 16.2234 64.2633 16.1237 64.5293 16.0572L64.6622 16.5226C64.4628 16.5891 64.2965 16.6556 64.1968 16.7553C64.0971 16.8551 63.9973 16.9548 63.9308 17.121C63.8644 17.2872 63.8311 17.4535 63.8311 17.6529C63.8311 17.8856 63.8644 18.0851 63.9308 18.2513C63.9973 18.4176 64.0971 18.5505 64.1968 18.6503C64.3298 18.75 64.4295 18.8497 64.5957 18.883C64.8285 18.9827 65.0944 19.016 65.3936 19.016C65.7261 19.016 66.0253 18.9495 66.258 18.8497C66.4907 18.7168 66.6569 18.5505 66.7899 18.3178C66.8896 18.0851 66.9561 17.8524 66.9561 17.5864C66.9561 17.3537 66.9229 17.1543 66.8231 16.9215C66.7234 16.6888 66.6569 16.5559 66.5572 16.4229H65.7926V17.6862Z"
                    fill={color}
                />
                <path
                    d="M67.3218 15.7247L63.4321 14.2287V13.6636L67.3218 12.0678V12.6662L66.1582 13.1316V14.7606L67.3218 15.1928V15.7247ZM65.726 14.5944V13.2646L64.6622 13.6636C64.3297 13.7965 64.0638 13.863 63.8643 13.9295C64.097 13.9628 64.363 14.0625 64.5957 14.129L65.726 14.5944Z"
                    fill={color}
                />
                <path
                    d="M67.3218 11.9681H66.8563L64.363 9.97341C64.1968 9.84043 64.0305 9.70745 63.8976 9.57447V11.7354H63.4321V8.97607H63.8976L66.5904 11.1702L66.8563 11.4029V8.90958H67.3218V11.9681V11.9681Z"
                    fill={color}
                />
                <path d="M67.3218 8.24468H63.4321V7.74601H67.3218V8.24468Z" fill={color} />
                <path
                    d="M67.3218 6.8484H63.4321V6.31648L66.4906 4.28856H63.4321V3.78989H67.3218V4.3218L64.2632 6.34973H67.3218V6.8484Z"
                    fill={color}
                />
                <path
                    d="M67.3218 2.89229H63.4321V0.099734H63.8976V2.39362H65.0944V0.232713H65.5598V2.39362H66.8896V0H67.355V2.89229H67.3218Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};
ExpandedLogo.propTypes = {
    color: PropTypes.string
};
export default ExpandedLogo;
