import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';

// project imports
import config from 'config';
import CompressedLogo from 'ui-component/Logo/CompressedLogo';
import ExpandedLogo from 'ui-component/Logo/ExpandedLogo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ compressed, logoFill, displayHandler }) => (
    <ButtonBase disableRipple component={Link} to={config.basename} style={{ display: displayHandler ? 'flex' : 'none' }}>
        {compressed ? <CompressedLogo color={logoFill} /> : <ExpandedLogo color={logoFill} />}
        {/* cieloterradesign */}
    </ButtonBase>
);
LogoSection.propTypes = {
    compressed: PropTypes.bool,
    logoFill: PropTypes.string,
    displayHandler: PropTypes?.bool
};

export default LogoSection;
