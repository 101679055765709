// assets
import { DashboardTwoTone } from '@mui/icons-material';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

// constant
const icons = { DashboardOutlinedIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    // title: 'Dashboard',
    type: 'group',
    children: [
        // {
        //     id: 'frontend',
        //     title: 'Cieloterra Design',
        //     type: 'item',
        //     url: '/',
        //     icon: icons.IconHome,
        //     breadcrumbs: false
        // },
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/ctdadmin',
            // url: '/admin/dashboard',
            icon: DashboardTwoTone,
            // chip: {
            //     label: 'pappa',
            //     color: 'primary'
            // },
            breadcrumbs: false
        }
    ]
};

export default dashboard;
