import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Box, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { BrowserView, MobileView } from 'react-device-detect';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            // case 'collapse':
            //     return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <BrowserView>
                <Box
                    sx={{
                        // border: '1px solid red',
                        height: '100vh',
                        margin: 0,
                        padding: 0
                    }}
                >
                    <Box
                        sx={{
                            height: '100vh',
                            // width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            // justifyContent: 'flex-start',
                            margin: '0 auto',
                            maxWidth: '1280px'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                padding: '15vh 0 30vh 0',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                                // justifyContent: 'flex-start',
                            }}
                        >
                            {items}
                        </Box>
                    </Box>
                </Box>
            </BrowserView>

            <MobileView>
                <Box
                    sx={{
                        // border: '1px solid red',
                        // height: '100vh',
                        margin: 0,
                        padding: 0
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            padding: '15vh 0 75% 0',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >
                        {items}
                    </Box>
                </Box>
            </MobileView>
            {/* </Typography> */}
            {/* {items} */}
            {/* group divider */}
            {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
