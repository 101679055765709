/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

import { CssBaseline } from '@mui/material';

// import { textTransform } from '@mui/system';

export default function themeTypography(theme) {
    return {
        // fontFamily: theme?.customization?.fontFamily,
        //* MENU
        fontFamily: `'SkySansW01', sans-serif`,
        navbarSection: {
            // position: 'absolute',
            fontSize: '3.75rem',
            textTransform: 'none',

            fontWeight: 300,
            textDecoration: 'none',
            // letterSpacing: '.3em',
            // WebkitTextStroke: `5px ${theme.darkTextSecondary}`,
            fontFamily: `'outward', sans-serif`
        },
        topicItem: {
            // fontSize: '15rem',
            lineHeight: 'initial',
            color: theme.primaryMain,
            textTransform: 'initial',
            letterSpacing: '.01rem',
            fontWeight: 300,
            // letterSpacing: '.3em',
            // WebkitTextStroke: `5px ${theme.darkTextSecondary}`,
            fontFamily: `'outward', sans-serif`
        },
        activeTopicItem: {
            // fontSize: '15rem',
            lineHeight: 'initial',
            color: theme.background,
            textTransform: 'none',
            letterSpacing: '.01rem',
            fontWeight: 300,
            // letterSpacing: '.3em',
            // WebkitTextStroke: `5px ${theme.darkTextSecondary}`,
            fontFamily: `'outward', sans-serif`
        },
        //* HOME
        scrollingHeader: {
            fontSize: '6rem',
            lineHeight: 'initial',
            color: theme.background,
            textTransform: 'uppercase',
            // letterSpacing: '.05em',
            fontWeight: 300,
            letterSpacing: '.01em',
            // WebkitTextStroke: `5px ${theme.darkTextSecondary}`,
            fontFamily: `'outward', sans-serif`
        },
        featuredTitle: {
            // fontSize: '4.5rem',
            color: theme.bodyText,
            lineHeight: 'initial',
            fontWeight: 400
        },
        historyTitle: {
            fontSize: '1.875rem',
            color: theme.bodyText,
            lineHeight: 'initial',
            fontWeight: 400
        },
        featuredArticleBody: {
            // fontSize: '1.25rem',
            color: theme.bodyText,
            // lineHeight: '1.5rem',
            fontWeight: 400
        },
        historySummary: {
            fontSize: '1.25rem',
            color: theme.bodyText,
            lineHeight: '1.75rem',
            fontWeight: 400
        },
        featuredSummary: {
            fontSize: '1rem',
            color: theme.bodyText,
            lineHeight: '1.25rem',
            letterSpacing: '0.15rem',
            fontWeight: 500,
            textTransform: 'uppercase'
        },
        featuredSection: {
            lineHeight: '1.25rem',
            fontWeight: 400
        },
        categoryName: {
            fontSize: '1.25rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.05rem'
        },
        ctaButton: {
            fontSize: '1.25rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.1rem'
        },
        author: {
            fontSize: '1.25rem',
            fontWeight: 500,
            letterSpacing: '0.01rem',
            color: theme.background
        },
        date: {
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.025rem',
            color: theme.background,
            textTransform: 'capitalize'
        },
        archiCta: {
            fontSize: '1rem',
            fontWeight: 400,
            color: theme.background
        },
        mdwHeader: {
            fontWeight: 300,
            letterSpacing: '0.1rem',
            color: theme.background,
            lineHeight: '0',
            // letterSpacing: '.3em',
            fontFamily: `'outward', sans-serif`
        },
        archiClaim: {
            fontSize: '9rem',
            lineHeight: 'initial',
            color: theme.background,
            // letterSpacing: '.0025em',
            fontWeight: 300,
            fontFamily: `'outward', sans-serif`
        },
        mdwYear: {
            fontSize: '9rem',
            lineHeight: '1em',
            color: 'transparent',
            textTransform: 'uppercase',
            // letterSpacing: '.0025em',
            fontWeight: 300,
            WebkitTextStroke: `3px ${theme.background}`,
            fontFamily: `'outward', sans-serif`,
            textDecoration: 'none',
            cursor: 'pointer',
            '&:hover, &:focus, &:active': { color: theme.background, WebkitTextStroke: '0px' }
        },
        mdwSummary: {
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
            fontWeight: 400,
            color: theme.background
        },

        //* CONTRIBUTOR
        contributorName: {
            // letterSpacing: '.01rem',
            color: theme.primaryMain,
            fontWeight: 500,
            lineHeight: 'initial'
        },
        //* EDITORIAL
        editorialHeading: {
            // letterSpacing: '.01rem',
            color: theme.background,
            fontWeight: 400,
            lineHeight: 'initial'
        },
        editorialBody: {
            fontSize: '1.25rem',
            color: theme.primaryMain,
            lineHeight: '1.75rem',
            fontWeight: 400
        },
        editorialArticles: {
            fontSize: '15rem',
            letterSpacing: '.1rem',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`
        },
        editorialArticleTitle: {
            // fontSize: '4.5rem',
            color: theme.bodyText,
            lineHeight: 'initial',
            fontWeight: 500
        },
        shareArticle: {
            fontSize: '1rem',
            lineHeight: 'initial',
            color: theme.primaryMain,
            fontWeight: 700
        },
        ctaSpan: {
            fontSize: '1.25rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '0.05rem'
        },
        //* ABOUT?
        sectionHeading: {
            fontSize: '15rem',
            letterSpacing: '.01rem',
            color: theme.background,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`
        },
        textClaim: {
            fontSize: '12rem',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            letterSpacing: '.01em',
            fontFamily: `'outward', sans-serif`,
            display: 'block'
        },
        lastClaim: {
            fontSize: '6rem',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            letterSpacing: '.01em',
            fontFamily: `'outward', sans-serif`,
            display: 'block'
        },
        paragraphHeading: {
            fontSize: '11vw',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`,
            display: 'block'
        },
        aboutBody: {
            fontSize: '1.25rem',
            color: theme.bodyText,
            lineHeight: '1.75rem',
            fontWeight: 400
        },
        genericHeader: {
            fontSize: '2rem',
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 500
        },
        stayInformed: {
            fontSize: '1rem',
            color: theme.primaryMain,
            fontWeight: 600,
            letterSpacing: '0.1rem',
            textTransform: 'uppercase'
        },
        moduleName: {
            fontSize: '1rem',
            color: theme.primaryMain,
            fontWeight: 600,
            // letterSpacing: '0.1rem',
            textTransform: 'uppercase'
        },
        //* REDAZIONE
        redazioneTitle: {
            color: theme.background,
            textAlign: 'center',
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`,
            textTransform: 'uppercase',
            letterSpacing: '0.01rem'
        },
        redazioneHeader: {
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 500
        },
        contributorsHeader: {
            color: theme.primaryMain,
            textAlign: 'center',
            fontWeight: 300,
            letterSpacing: '0.1rem',
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`
        },
        contributor: {
            fontSize: '1.25rem',
            fontWeight: 500
        },
        paginationNo: {
            fontSize: '1.25rem',
            fontWeight: 700
        },
        //* ARTICLE
        articleTitle: {
            fontSize: '4rem',
            color: theme.bodyText,
            lineHeight: 'initial',
            fontWeight: 500
        },
        articleSubtitle: {
            fontSize: '.9rem',
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 500,
            textTransform: 'uppercase'
        },
        articleAuthor: {
            fontSize: '3rem',
            color: theme.primaryMain,
            fontWeight: 300,
            marginTop: '.1em', //! SOLUZIONE CHE FA SCHIFO
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`
        },
        //* FOOTER
        subscribeHeader: {
            fontSize: '1rem',
            color: theme.primaryMain,
            fontWeight: 700,
            letterSpacing: '0.1rem',
            textTransform: 'uppercase'
        },
        newsletterDecor: {
            fontSize: '4rem',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`,
            display: 'block'
        },
        footerClaim: {
            fontSize: '20.5vw',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`,
            display: 'block'
        },
        subtle: {
            fontSize: '.9rem',
            color: theme.subtleText,
            lineHeight: 'initial',
            fontWeight: 400
        },
        textLink: {
            fontSize: '.9rem',
            fontWeight: 400,
            color: theme.subtleText,
            textDecoration: 'underline',
            lineHeight: 'initial',
            textTransform: 'none'
        },
        archiveExplore: {
            fontSize: '6rem',
            textAlign: 'center',
            letterSpacing: '0.025rem',
            color: theme.secondaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`,
            display: 'block'
        },
        footerRef: {
            fontSize: '.7rem',
            color: theme.background,
            fontWeight: 700
            // textAlign: 'center'
            // fontFamily: `'outward', sans-serif`
        },
        footerMenu: {
            fontSize: '.9rem',
            fontWeight: 600,
            color: theme.bodyText,
            lineHeight: 'initial',
            textDecoration: 'none',
            textTransform: 'uppercase'
        },
        dialogTitle: {
            fontSize: '1.5rem',
            // color: theme.darkTextPrimary,
            fontWeight: 500,
            lineHeight: 'initial'
        },
        buttonText: {
            fontSize: '1rem',
            fontWeight: 600,
            color: theme.primaryMain,
            letterSpacing: '.05rem'
            // fontWeight: 600,
        },
        labelText: {
            fontSize: '.875rem',
            fontWeight: 600,
            textTransform: 'uppercase'
            // color: theme.primaryM
            // letterSpacing: '.05rem'
            // fontWeight: 600,
        },
        fileUploadLabel: {
            fontSize: '1rem',
            color: theme.bodyText,
            fontWeight: 600
            // letterSpacing: '0.1rem'
            // textTransform: 'uppercase'
        },
        fileUploadValue: {
            fontSize: '1rem',
            color: theme.bodyText,
            fontWeight: 600
            // letterSpacing: '0.1rem'
            // textTransform: 'uppercase'
        },
        /* OTHER */
        testTypography: {
            fontSize: '5rem',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            fontFamily: `'outward', sans-serif`
        },
        editorAuthor: {
            fontSize: '1.3rem',
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 700
        },
        articleDate: {
            fontSize: '1.3rem',
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 500
        },
        articleBody: {
            fontSize: '1.875rem',
            color: theme.bodyText,
            lineHeight: '2.25rem',
            fontWeight: 400
        },
        fullDeskQuote: {
            color: theme.primaryMain,
            fontSize: '4rem',
            lineHeight: 'initial',
            fontWeight: 500
        },
        fullMobQuote: {
            color: theme.primaryMain,
            fontSize: '4rem',
            lineHeight: 'initial',
            fontWeight: 500
        },
        imageCaption: {
            fontSize: '1rem',
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 500
        },
        videoEmbed: {
            fontSize: '1.25rem',
            color: theme.primaryMain,
            lineHeight: 'initial',
            fontWeight: 500
        },
        h6: {
            fontWeight: 500,
            color: theme.heading,
            fontSize: '0.75rem'
            // fontFamily: `'outward', sans-serif`
        },
        h5: {
            fontSize: '.875rem',
            color: theme.primaryMain,
            fontWeight: 700
            // textAlign: 'center'
            // fontFamily: `'outward', sans-serif`
        },
        h4: {
            fontSize: '1rem',
            color: theme.primaryMain,
            fontWeight: 600,
            textTransform: 'uppercase'
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.heading,
            fontWeight: 600
            // fontFamily: `'outward', sans-serif`
        },
        h2: {
            fontSize: '1.5rem',
            color: theme.heading,
            fontWeight: 700
            // fontFamily: `'outward', sans-serif`
        },
        h1: {
            fontSize: '4rem',
            color: theme.bodyText,
            fontWeight: 500
            // letterSpacing: '.3em',
            // fontFamily: `'outward', sans-serif`
        },
        dashboardh1: {
            fontSize: '4rem',
            color: theme.bodyText,
            fontWeight: 400
            // letterSpacing: '.3em',
            // fontFamily: `'outward', sans-serif`
        },
        titleh1: {
            fontSize: '24rem',
            color: theme.primaryMain,
            fontWeight: 300,
            lineHeight: 'initial',
            letterSpacing: '0',
            fontFamily: `'outward', sans-serif`
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.textDark
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: theme.darkTextSecondary
        },
        caption: {
            fontSize: '0.75rem',
            color: theme.darkTextSecondary,
            fontWeight: 400
        },
        menuItem: {
            fontSize: '.9rem',
            color: theme.darkTextSecondary,
            fontWeight: 500
        },
        menuItemSelected: {
            fontSize: '.95rem',
            color: theme.darkTextSecondary,
            fontWeight: 500
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.darkTextPrimary
        },
        button: {
            textTransform: 'uppercase'
        },
        // unused
        inlineButton: {
            textTransform: 'lowercase'
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: theme.grey500,
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            // backgroundColor: theme.background,
            width: '100%'
            //! PRODUCTION
            // minHeight: 'calc(100vh - 72px)',
            // flexGrow: 1,
            // padding: '0'
            // marginTop: '66px',
            // marginTop: '88px', // boh
            // marginRight: '20px',
            // borderRadius: `${theme?.customization?.borderRadius}px`
            // borderRadius: 0
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.heading,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: theme.darkTextSecondary,
            textTransform: 'capitalize'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        }
    };
}
