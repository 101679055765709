import ReactDOM from 'react-dom';
import { onLCP, onFID, onCLS } from 'web-vitals';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// project imports
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { store } from 'store';
// import ctd from './consolr';

// style + assets
import 'assets/scss/style.scss';
import { UserProvider } from 'context/UserContext';

import QSERV_ENDPOINT from './constants';

const client = new ApolloClient({
    uri: `${QSERV_ENDPOINT}/graphql`,
    cache: new InMemoryCache(),
    // link: HttpLink,
    connectToDevTools: true
});

// ctd('client', client);
// ==============================|| REACT DOM RENDER  ||============================== //

onLCP(console.log);
onFID(console.log);
onCLS(console.log);

ReactDOM.render(
    // <HelmetProvider>
    <ApolloProvider client={client}>
        <Provider store={store}>
            <UserProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </UserProvider>
        </Provider>
    </ApolloProvider>,
    // </HelmetProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
