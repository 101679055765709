// ==============================|| FRONTEND MENU ITEMS ||============================== //

const topPages = {
    id: 'top-frontend',
    title: 'Frontend',
    type: 'topic',
    children: [
        {
            id: 'frontend-design',
            title: 'Design & Designers',
            type: 'item',
            url: 'editorial/design-designers',
            breadcrumbs: false
            // disabled: true
        },
        {
            id: 'frontend-archi',
            title: 'Architettura',
            type: 'item',
            url: 'editorial/architettura',
            breadcrumbs: false
            // disabled: true
        },
        {
            id: 'frontend-case',
            title: 'Ho visto case',
            type: 'item',
            url: 'editorial/ho-visto-case',
            breadcrumbs: false
            // disabled: true
        },
        {
            id: 'frontend-envisuel',
            title: 'Envisuel',
            type: 'item',
            url: 'editorial/envisuel',
            breadcrumbs: false
            // disabled: true
        },
        {
            id: 'frontend-stili',
            title: 'Manuale di stili',
            type: 'item',
            url: 'editorial/manuale-di-stili',
            breadcrumbs: false
            // disabled: true
        },
        {
            id: 'frontend-coolture',
            title: 'Coolture',
            type: 'item',
            url: 'editorial/coolture',
            breadcrumbs: false
            // disabled: true
        },
        {
            id: 'frontend-mdw',
            title: 'Milan Design Week',
            type: 'item',
            url: 'editorial/milan-design-week/2024',
            breadcrumbs: false,
            last: true
            // disabled: true
        }
    ]
};
const bottomPages = {
    id: 'bottom-frontend',
    title: 'Frontend',
    type: 'page',
    children: [
        // {
        //     id: 'frontend-home',
        //     title: 'Home',
        //     type: 'item',
        //     url: '',
        //     breadcrumbs: false
        // },
        {
            id: 'frontend-about',
            title: 'About',
            type: 'item',
            url: 'about',
            breadcrumbs: false
        },
        {
            id: 'frontend-facebook',
            // title: 'Facebook',
            type: 'item',
            url: '',
            icon: 'fb',
            breadcrumbs: false,
            external: 'yo',
            target: true
        },
        {
            id: 'frontend-redazione',
            title: 'Redazione',
            type: 'item',
            url: 'redazione',
            breadcrumbs: false
        },
        {
            id: 'frontend-instagram',
            // title: 'Instagram',
            type: 'item',
            url: '',
            icon: 'ig',
            breadcrumbs: false,
            last: true,
            external: 'yo',
            target: true
        }
        // {
        //     id: 'frontend-archivio',
        //     title: 'Archivio',
        //     type: 'item',
        //     url: 'archivio',
        //     breadcrumbs: false,
        //     last: true
        //     // disabled: true
        // }
    ]
};
const mobileBottomSections = {
    id: 'bottom-frontend',
    title: 'Frontend',
    type: 'page',
    children: [
        // {
        //     id: 'frontend-home',
        //     title: 'Home',
        //     type: 'item',
        //     url: '',
        //     breadcrumbs: false
        // },
        {
            id: 'frontend-about',
            title: 'About',
            type: 'item',
            url: 'about',
            breadcrumbs: false
        },
        {
            id: 'frontend-redazione',
            title: 'Redazione',
            type: 'item',
            url: 'redazione',
            breadcrumbs: false
        }
    ]
};
const mobileBottomSocials = {
    id: 'bottom-frontend',
    title: 'Frontend',
    type: 'page',
    children: [
        {
            id: 'frontend-facebook',
            // title: 'Facebook',
            type: 'item',
            url: '',
            icon: 'facebook',
            breadcrumbs: false,
            external: 'yo',
            target: true
        },
        {
            id: 'frontend-instagram',
            // title: 'Instagram',
            type: 'item',
            url: '',
            icon: 'instagram',
            breadcrumbs: false,
            last: true,
            external: 'yo',
            target: true
        }
    ]
};

export { topPages, bottomPages, mobileBottomSections, mobileBottomSocials };
