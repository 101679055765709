// assets
import { IconKey } from '@tabler/icons';
// import  from '@mui/icons-material/BadgeOutlined';
import {
    BadgeOutlined,
    FeedTwoTone,
    HistoryEdu,
    HistoryEduOutlined,
    HistoryEduTwoTone,
    HomeOutlined,
    NewReleasesTwoTone,
    NewspaperOutlined,
    NewspaperTwoTone,
    PeopleAltOutlined,
    PermMediaTwoTone,
    PhotoLibraryTwoTone
} from '@mui/icons-material';

// constant
const icons = {
    IconKey,
    BadgeOutlined
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const editors = {
    id: 'editors',
    title: 'Redazione',
    // caption: 'Pages Caption',
    accessLevel: 2,
    type: 'group',
    children: [
        {
            id: 'util-articles',
            title: 'Articoli',
            type: 'item',
            url: 'articles',
            icon: FeedTwoTone,
            breadcrumbs: false,
            accessLevel: 2
        },
        {
            id: 'util-authors',
            title: 'Autori',
            type: 'item',
            url: 'authors',
            icon: HistoryEduTwoTone,
            breadcrumbs: false,
            accessLevel: 2
        },
        {
            // disabled: true,
            // chip: {
            //     // avatar: <NewReleasesTwoTone color="background" fontSize="small" />,
            //     label: 'Nuovo',
            //     color: 'primary'
            // },
            id: 'page-media',
            title: 'Libreria',
            type: 'item',
            url: 'media',
            icon: PermMediaTwoTone,
            breadcrumbs: false,
            accessLevel: 3
        }
    ]
};

export default editors;
