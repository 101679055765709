import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import editors from './editors';
import { topPages, bottomPages, mobileBottomSections } from './frontend';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, editors, pages, other]
    // items: [dashboard, utilities, pages]
};

const frontendItems = {
    items: [topPages]
};

const basePages = {
    items: [bottomPages]
};

const mobBasePages = {
    items: [mobileBottomSections]
};

// const bottomItems = {
//     items: [bottomPages]
// };

export { menuItems, frontendItems, basePages, mobBasePages };
