// material-ui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import ShadertoyReact from 'shadertoy-react';
import skyShader from 'ui-component/shaders/ctd-cielo';
// styles
const ShaderContainer = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    overflowY: 'hidden',
    pointerEvents: 'none',
    height: '100%',
    zIndex: '-1'
});

//! ADD DEVICE BASED PRECISION AND DEVICE PIXEL RATIO
const MenuShader = ({ precision, pixelRatio }) => (
    <ShaderContainer>
        <ShadertoyReact precision="lowp" devicePixelRatio={0.1} fs={skyShader} />
    </ShaderContainer>
);
MenuShader.propTypes = {
    precision: PropTypes.string,
    pixelRatio: PropTypes.number
};
export default MenuShader;
