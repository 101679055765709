// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import {
    AlternateEmail,
    AnalyticsTwoTone,
    ContactPageOutlined,
    ContactPageTwoTone,
    MailOutlined,
    MailTwoTone,
    PrivacyTipOutlined,
    PrivacyTipTwoTone,
    ShareOutlined,
    ShareTwoTone
} from '@mui/icons-material';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    title: 'Integrazioni',
    id: 'ctd-integrations',
    accessLevel: 1,
    type: 'group',
    // caption: 'Integrazioni',
    children: [
        {
            id: 'contacts',
            title: 'Form di contatto',
            type: 'item',
            url: 'contacts',
            icon: ContactPageTwoTone
            // external: true,
            // target: true
        },
        {
            // disabled: true,
            id: 'social',
            title: 'E-mail e social',
            type: 'item',
            url: 'social',
            icon: ShareTwoTone
            // external: true,
            // target: true
        },
        {
            // disabled: true,
            id: 'newsletter',
            title: 'Newsletter',
            type: 'item',
            url: 'newsletter',
            icon: MailTwoTone
            // external: true,
            // target: true
        },
        {
            // disabled: true,
            id: 'privacy',
            title: 'Privacy e cookies',
            type: 'item',
            url: 'privacy',
            icon: PrivacyTipTwoTone
            // external: true,
            // target: true
        },
        {
            // chip: {
            //     // avatar: <NewReleasesTwoTone color="background" fontSize="small" />,
            //     label: 'Nuovo',
            //     color: 'primary'
            // },
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            url: 'analytics',
            icon: AnalyticsTwoTone
            // external: true,
            // target: true
        }
    ]
};

export default other;
