// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const CloseIcon = ({ iconFill }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={iconFill}
                d="M4.30886 0.954816L8.31371 4.95967L12.2685 1.00488L15.6226 4.35894L11.6678 8.31373L15.6226 12.2685L12.2685 15.6226L8.31371 11.6678L4.35892 15.6226L1.00485 12.2685L4.95964 8.31373L0.954792 4.30888L4.30886 0.954816Z"
            />
        </svg>
    );
};
CloseIcon.propTypes = {
    iconFill: PropTypes.string
};

export default CloseIcon;
