// assets
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { BadgeOutlined, KeyOutlined } from '@mui/icons-material';
// constant
const icons = {
    NewspaperOutlinedIcon,
    GroupOutlinedIcon,
    ContactPageOutlinedIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Gestione',
    type: 'group',
    // accessLevel: 2,
    children: [
        // {
        //     id: 'util-authors',
        //     title: 'Autori',
        //     type: 'item',
        //     url: 'admin/authors',
        //     icon: icons.IconUser,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-roles',
        //     title: 'Ruoli',
        //     type: 'item',
        //     url: '#',
        //     icon: BadgeOutlined,
        //     breadcrumbs: false,
        //     disabled: true
        // },
        // {
        //     // disabled: true,
        //     id: 'authentication',
        //     title: 'Account',
        //     type: 'item',
        //     // type: 'collapse',
        //     url: 'me',
        //     icon: KeyOutlined
        //     // children: [
        //     //     {
        //     //         id: 'login3',
        //     //         title: 'Login',
        //     //         type: 'item',
        //     //         url: '/admin/login',
        //     //         target: true
        //     //     },
        //     //     {
        //     //         id: 'register3',
        //     //         title: 'Register',
        //     //         type: 'item',
        //     //         url: '/admin/register',
        //     //         target: true
        //     //     }
        //     // ]
        // }
    ]
};

export default utilities;
