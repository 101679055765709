import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, Button, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
import config from 'config';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon;
    const itemIcon = item?.icon && <Icon stroke={1.5} size="1.3rem" />;

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        // dispatch({ type: MENU_OPEN, id });
        dispatch({ type: SET_MENU, id, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Button
            {...listItemProps}
            disabled={item.disabled}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disableElevation
            variant="text"
            sx={{
                whiteSpace: 'nowrap',
                // borderRadius: `${customization.borderRadius}px`,
                borderRadius: 0
                // mx: '.5em',
                // alignItems: 'flex',
                // backgroundColor: level > 1 ? 'transparent !important' : 'inherit'
                // py: level > 1 ? 1 : 1.25
                // pl: `${level * 24}px`
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            {/* <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon> */}

            <Typography
                sx={{ fontSize: matchesSM ? '7rem' : '18rem' }}
                variant="topicItem"
                color={theme.palette.secondary.bg}
                // color="inherit"
            >
                /&nbsp;
            </Typography>
            <Typography
                sx={{ fontSize: matchesSM ? '7rem' : '18rem' }}
                // variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'activeTopicItem' : 'topicItem'}
                variant="activeTopicItem"
                // color="inherit"
            >
                {item.title}
            </Typography>

            {/* {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )} */}
        </Button>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
