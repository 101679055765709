import PropTypes from 'prop-types';
import { render } from 'react-dom';
import MenuShader from 'ui-component/MenuShader';

// material-ui
import { Box, Drawer, Fade, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import FooterMenu from './FooterMenu';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { frontendDrawerWidth } from 'store/constant';

const Sidebar = ({ drawerOpen, drawerToggle, window, socialLinks }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    const drawer = (
        <>
            <BrowserView>
                <Box
                    sx={{
                        maskImage:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 93%, rgba(0, 0, 0, 0) 100%)',
                        height: '100vh',
                        overflow: 'auto',
                        margin: 0,
                        padding: 0
                    }}
                >
                    <MenuList />
                </Box>
                <FooterMenu socialLinks={socialLinks} />
            </BrowserView>
            <MobileView>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        maskImage:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 93%, rgba(0, 0, 0, 0) 100%)',
                        height: '100%',
                        overflow: 'auto',
                        margin: 0,
                        padding: 0,
                        '::-webkit-scrollbar': {
                            width: 0 /* Mostly for vertical scrollbars */,
                            height: 0,
                            display: 'none' /* Mostly for horizontal scrollbars */
                        }
                    }}
                >
                    <Box sx={{ px: 2 }}>
                        <MenuList />
                    </Box>
                </Box>
                <FooterMenu socialLinks={socialLinks} />
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
            {/* <Box sx={{ height: '100vh', overflowY: 'none' }}> */}
            {/* TODO: change component, maybe */}
            <Drawer
                container={container}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                transitionDuration={0}
                sx={{
                    // zIndex: 9999999999999999,
                    ...(!drawerOpen && {
                        opacity: 0,
                        transition: theme.transitions.create('opacity', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: 200
                        })
                    }),
                    ...(drawerOpen && {
                        transition: theme.transitions.create('opacity', {
                            easing: theme.transitions.easing.easeInOut,
                            duration: 300
                        }),
                        opacity: 1
                    }),
                    '& .MuiDrawer-paper': {
                        // zIndex: 0,
                        // transition: 'all .3s ease-in-out',
                        // width: frontendDrawerWidth,
                        // background: `linear-gradient(-5deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.bg} 100%)`,
                        color: theme.palette.background.paper,
                        // height: 'calc(100vh-72px)',
                        // display: 'flex',
                        // flexDirection: 'column',
                        // height: '100%',
                        // margin: '0 auto',
                        width: '100%'

                        // top: '72px'
                        // borderRight: 'none',
                        // [theme.breakpoints.up('md')]: {
                        //     // top: '66px'
                        // }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
                <MenuShader precision="lowp" pixelRatio={matchUpMd ? 0.1 : 0.2} />
            </Drawer>
            {/* </Box> */}
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object,
    socialLinks: PropTypes?.array
};

export default Sidebar;
