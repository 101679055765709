// assets
import { IconKey } from '@tabler/icons';
import {
    CategoryOutlined,
    CategoryTwoTone,
    HomeOutlined,
    HomeTwoTone,
    InfoOutlined,
    InfoTwoTone,
    KeyOutlined,
    PeopleAltOutlined,
    PeopleAltTwoTone,
    PhotoLibraryTwoTone
} from '@mui/icons-material';
// constant

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Pagine',
    // caption: 'Pages Caption',
    accessLevel: 1,
    type: 'group',
    children: [
        {
            // disabled: true,

            id: 'page-home',
            title: 'Home',
            type: 'item',
            url: 'home',
            icon: HomeTwoTone,
            breadcrumbs: false
        },
        {
            // disabled: true,
            id: 'page-editorial',
            title: 'Categorie editoriali',
            type: 'item',
            url: 'editorials',
            icon: CategoryTwoTone,
            breadcrumbs: false
        },

        {
            disabled: true,

            id: 'page-redazione',
            title: 'Redazione',
            type: 'item',
            url: 'redazione',
            icon: PeopleAltTwoTone,
            breadcrumbs: false
        },
        {
            disabled: true,

            id: 'page-about',
            title: 'About',
            type: 'item',
            url: 'about',
            icon: InfoTwoTone,
            breadcrumbs: false
        }
        // {
        //     disabled: true,
        //     id: 'authentication',
        //     title: 'Authentication',
        //     type: 'collapse',
        //     icon: KeyOutlined,
        //     children: [
        //         {
        //             id: 'login3',
        //             title: 'Login',
        //             type: 'item',
        //             url: '/admin/login/login3',
        //             target: true
        //         },
        //         {
        //             id: 'register3',
        //             title: 'Register',
        //             type: 'item',
        //             url: '/admin/register/register3',
        //             target: true
        //         }
        //     ]
        // }
    ]
};

export default pages;
